import React, {useState, useEffect} from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import {styleBox} from '../../../../utils/views/style'

// apis
import { deleteRoleApi, getRoleApiById, saveRoleApi, updateRoleApi } from '../../../../apis/role';
import { getAbilityApi } from '../../../../apis/ability';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [label, setLabel] = useState('')
  const [abilitiesRows, setAbilitiesRows] = useState([])
  const [selectedAbility, setSelectedAbility] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  
  // methods
  const handleClose = () => {
    setOpen(false)
  };

  const handleSaveRole = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      let response = await saveRoleApi({label, abilityIds: selectedAbility.map((e) => e.id)})
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateRole = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      await updateRoleApi(modalData.id,{label, abilityIds: selectedAbility.map((e) => e.id)})
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteRole = async () => {
    try {
      setErrorMessage('')
      let response = await deleteRoleApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }

  const classes = useStyles();
  
  useEffect(() => {
    receiveAbilities()
    if (modal != 'add') {

      setLabel(modalData.label)
      // setSelectedAbility(modalData.abilities)
    }
  }, []);

  const receiveAbilities = async () => {
    try {
      setAbilitiesRows([])
      let response = await getAbilityApi()
      response.data?.data.forEach(element => {
        setAbilitiesRows(oldItem => [...oldItem, { id: element.id, label: element.label}])
      });

      if (modal != 'add') {
        let res = await getRoleApiById(modalData.id)
        setSelectedAbility(res.data.data[0]?.abilities?.map((e) => ({id: e.id,label: e.label})))
      }
    } catch (error) {
      setErrorMessage(error)
    }
  }

  const handleSelectAbiltiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAbility(value);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} 
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ? 
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Role' : 'Update Role'}
              </Typography>
              <Box overflow="auto" maxHeight="80vh" fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveRole : handleUpdateRole}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    label="Nama Role"
                    autoFocus
                  />

                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Ability</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={selectedAbility}
                      onChange={handleSelectAbiltiesChange}
                      input={<OutlinedInput label="Ability" />}
                      renderValue={(selected) => selected.map(i => i.label).join(', ')}
                      MenuProps={MenuProps}
                    >
                      {abilitiesRows.map((ability) => (
                        <MenuItem key={ability.id} value={ability}>
                          <Checkbox checked={!!selectedAbility.find((e) => e.id == ability.id)} />
                          <ListItemText primary={ability.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ? 
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Role dengan ID
                    { 
                      modalData.map((item)=>(
                        <span> {item.id}, </span>
                      ))
                    }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
            :
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Apakah Anda ingin menghapus Role <span>{label}</span> ?
              </Typography>
              <center>
                <p>{errorMessage}</p>
              </center>
              <Box display="flex" justifyContent="end">
                <Button
                  color="outline"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {
                  !btnDelete ?
                    <Button
                      color="primary"
                      onClick={handleDeleteRole}
                    >
                      Delete
                    </Button>
                    : ''
                }
              </Box>
            </Box>
        }
        
      </Modal>
    </div>
  );
};

export default BasicModal;
