import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

// Bus Type
export const getBusTypeApi = async(page = 1, limit = 20, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/bus-types?${url.href.split('?')[1]}`, accessToken());
}
export const saveBusTypeApi = async(body)=>{
  return await httpClientPrivate.post(`/bus-types`, body,accessToken());
}
export const updateBusTypeApi = async(id, body)=>{
  return await httpClientPrivate.put(`/bus-types/${id}`, body,accessToken());
}
export const deleteBusTypeApi = async(id)=>{
  return await httpClientPrivate.delete(`/bus-types/${id}`, accessToken());
}
export const bulkDeleteBusTypeApi = async(body)=>{
  return await httpClientPrivate.post(`/bus-types/delete`, body, accessToken());
}

// Bus
export const getBusApi = async(page = 1, limit = 20, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/buses?${url.href.split('?')[1]}`, accessToken());
}
export const saveBusApi = async(body)=>{
  return await httpClientPrivate.post(`/buses`, body, accessToken());
}
export const updateBusApi = async(cityGroupId,body)=>{
  return await httpClientPrivate.put(`/buses/${cityGroupId}`, body, accessToken());
}
export const deleteBusApi = async(cityGroupId)=>{
  return await httpClientPrivate.delete(`/buses/${cityGroupId}`, accessToken());
}
export const bulkDeleteBusApi = async(body)=>{
  return await httpClientPrivate.post(`/buses/delete`, body, accessToken());
}