import React, {useEffect, useState} from "react";
import dayjs from 'dayjs';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// import PassengerModal from "./PassengerModal.jsx";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Limit from "components/Limit/Limit.js";
import SearchTable from "components/SearchTable/SearchTable.js";

//apis
import { downloadPassengerDataApi, getPassengerApi } from '../../../../apis/passenger.js';

//Icon
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import moment from 'moment';
import currencyFormatter from '../../../../utils/currencyFormatter.js';
import { TextField } from '@material-ui/core';

// lib
import { useDebouncedCallback } from "use-debounce";

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  },
  justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "kota", headerName: "Kota", width: 130 },
];


const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState(''); 
  const [modalData, setModalData] =  useState({})
  const [rows, setRows] =  useState([])
  //////
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPage, setTotalPage] = React.useState(1);

  // Filter
  const [startTime, setStartTime] = useState(null)
  const [startTimeDayJs, setStartTimeDayJs] = useState(null);
  const [endTime, setEndTime] = useState(null)
  const [endTimeDayJs, setEndTimeDayJs] = useState(null);
  const [search, setSearch] = useState("");
  const [originCityLabel, setOriginCityLabel] = useState("");
  const [destinationCityLabel, setDestinationCityLabel] = useState("");
  //////

  //methods
  const handleFilter = async () => {
    await receivePassenger(
      1,
      rowsPerPage,
      startTime,
      endTime,
      search,
      originCityLabel,
      destinationCityLabel
    )
  }
  
  const handleChangePage = async (event, newPage) => {
    setPage(newPage)
    await receivePassenger(
      newPage,
      rowsPerPage,
      startTime,
      endTime,
      search,
      originCityLabel,
      destinationCityLabel
    )
  };

  const handleLimitPage = async (value) => {
    setRowsPerPage(value)
    setPage(1)
    await receivePassenger(
      1,
      value,
      startTime,
      endTime,
      search,
      originCityLabel,
      destinationCityLabel
    )
  }

  const handleSearch = useDebouncedCallback((value) => {
    setSearch(value)
    setPage(1)
    receivePassenger(
      1,
      rowsPerPage,
      startTime,
      endTime,
      value,
      originCityLabel,
      destinationCityLabel
    )
   }, 1000)

  const handleOriginCityLabel = useDebouncedCallback((value) => {
    setOriginCityLabel(value)
    setPage(1)
    receivePassenger(
      1,
      rowsPerPage,
      startTime,
      endTime,
      search,
      value,
      destinationCityLabel
    )
   }, 1000)

   const handleDestinationCityLabel = useDebouncedCallback((value) => {
    setDestinationCityLabel(value)
    setPage(1)
    receivePassenger(
      1,
      rowsPerPage,
      startTime,
      endTime,
      search,
      originCityLabel,
      value
    )
   }, 1000)

  const handleOpen = async(modal, data={}) => {
    if(modal == 'bulkDelete'){ 
      let fill = await filteringPassenger(data)
      setModalData(fill)
    }
    else{setModalData(data)}
    setModal(modal)
    setOpen(true);
  }

  const receivePassenger = async(
    page,
    rowsPerPage,
    filterPassengerDateStart = null,
    filterPassengerDateEnd = null,
    search,
    originCityLabel,
    destinationCityLabel
  ) =>{
    try {
      setRows([])
      let response = await getPassengerApi(
        page,
        rowsPerPage,
        filterPassengerDateStart,
        filterPassengerDateEnd,
        search,
        originCityLabel,
        destinationCityLabel
      )
      setTotalPage(response.data.data.meta.last_page)
      setRows(response.data.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const filteringPassenger = async (data) => {
    let filter = []
    rows.forEach((item) => {
      data.forEach((items) => {
        if (items == item.id) return filter.push(item)
      })
    })
    return filter
  }

  const handleDownloadToday = async () => {
    try {
      const response = await downloadPassengerDataApi()
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `Data Penumpang ${moment().format("DD-MM-YYYY")}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    } catch(error) {
      console.log(error)
      if (error?.response?.data?.errors[0]?.message) {
        alert(error?.response?.data?.errors[0]?.message || error?.response?.data)
      }
    }
  }

  //react
  useEffect(() => {
    receivePassenger(
      page,
      rowsPerPage,
      startTime,
      endTime,
      search,
      originCityLabel,
      destinationCityLabel
    )
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Daftar Penumpang</h4>
            <p className={classes.cardCategoryWhite}>
              Daftar Penumpang
            </p>
          </CardHeader>
          <CardBody>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="start" 
              alignItems="center"
              marginBottom={2}
              gap={2}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="Tgl Mulai Keberangkatan"
                    value={startTimeDayJs}
                    onChange={(newValue) => { setStartTimeDayJs(newValue); setStartTime(newValue ? newValue.format('YYYY-MM-DD'): null) }}
                  />
                  <DatePicker
                    label="Tgl Berakhir Keberangkatan"
                    value={endTimeDayJs}
                    onChange={(newValue) => { setEndTimeDayJs(newValue); setEndTime(newValue ? newValue.format('YYYY-MM-DD'): null) }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Button variant="contained" color="primary" onClick={handleFilter}>
                Cari
              </Button>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} marginBottom={2}>
              <Button variant="contained" color="primary" onClick={handleDownloadToday}>
                Download Penumpang Hari Ini
              </Button>
              <Stack direction="row" alignItems="center" gap={2}>
                <SearchTable name="Cari Nama" onChange={handleSearch}/>
                <SearchTable name="Cari Rute Asal" onChange={handleOriginCityLabel}/>
                <SearchTable name="Cari Rute Tujuan" onChange={handleDestinationCityLabel}/>
              </Stack>
            </Stack>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Nama</StyledTableCell>
                    <StyledTableCell>Nomor HP</StyledTableCell>
                    <StyledTableCell>Nomor Kursi</StyledTableCell>
                    <StyledTableCell>Asal-Tujuan</StyledTableCell>
                    <StyledTableCell>Rute Bus</StyledTableCell>
                    <StyledTableCell>Tanggal Berangkat</StyledTableCell>
                    <StyledTableCell>Waktu Berangkat</StyledTableCell>
                    <StyledTableCell>Agen Keberangkatan</StyledTableCell>
                    {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.seatNumber}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.originDestinationLabel}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        [{row.departureCode}] {row.departureLabel}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.departureDate).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.pickupTime}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.pickupAgent}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        <DataSummaryIcon >
                          <div className="icon mr-1">
                            <PanToolAltOutlinedIcon onClick={() => handleOpen('detail', row)} />
                          </div>
                        </DataSummaryIcon>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
              <Limit value={rowsPerPage} onChange={handleLimitPage} />
              <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      {/* {open && <PassengerModal open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={() => {}} />} */}
    </GridContainer>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////


