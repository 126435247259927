import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import TransactionModal from "./TransactionModal.jsx";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import currencyFormatter from '../../../../utils/currencyFormatter.js';
import { Typography } from "@material-ui/core";
import Switch from '@mui/material/Switch';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Limit from "components/Limit/Limit.js";
import SearchTable from "components/SearchTable/SearchTable.js";

//apis
import { getCityApi, bulkDeleteCityApi } from '../../../../apis/city';
import { getTransactionApi } from '../../../../apis/transaction.js';

//Icon
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import moment from 'moment';
import SortIcon from '@mui/icons-material/Sort';

// lib
import { useDebouncedCallback } from "use-debounce";

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  },
  justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "kota", headerName: "Kota", width: 130 },
];


const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState('');
  const [modalData, setModalData] = useState({})
  const [rows, setRows] = useState([])
  //////
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [withFilter, setWithFilter] = React.useState(false);

  const [totalPage, setTotalPage] = React.useState(1);
  const [alignment, setAlignment] = useState('Transaction_date');
  const [sortAsc, setSortAsc] = useState(true);

  // Filter
  const [startTime, setStartTime] = useState(null)
  const [startTimeDayJs, setStartTimeDayJs] = useState(null);
  const [endTime, setEndTime] = useState(null)
  const [endTimeDayJs, setEndTimeDayJs] = useState(null);
  const [departureDate, setDepartureDate] = React.useState(null);
  const [departureDateDayJs, setDepartureDateDayJs] = useState(null);
  const [search, setSearch] = useState("");
  const [departureLabel, setDepartureLabel] = useState("");
  //////

  // Continue create filter fields
  //methods
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChangeOrientation = (event) => {
    setSortAsc(!event.target.value);
  };

  const handleFilter = async () => {
    setWithFilter(true)
    await receiveTransaction(
      1,
      rowsPerPage,
      startTime,
      endTime,
      departureDate,
      search,
      departureLabel
    )
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage)
    await receiveTransaction(
      newPage,
      rowsPerPage,
      startTime,
      endTime,
      departureDate,
      search,
      departureLabel
    )
  };

  const handleLimitPage = async (value) => {
    setRowsPerPage(value)
    setPage(1)
    await receiveTransaction(
      1,
      value,
      startTime,
      endTime,
      departureDate,
      search,
      departureLabel
    )
  }

  const handleSearch = useDebouncedCallback((value) => {
    setSearch(value)
    setPage(1)
    receiveTransaction(
      1,
      rowsPerPage,
      startTime,
      endTime,
      departureDate,
      value,
      departureLabel
    )
   }, 1000)

  const handleDepartureLabel = useDebouncedCallback((value) => {
    setDepartureLabel(value)
    setPage(1)
    receiveTransaction(
      1,
      rowsPerPage,
      startTime,
      endTime,
      departureDate,
      search,
      value
    )
   }, 1000)

  const handleOpen = async (modal, data = {}) => {
    if (modal == 'bulkDelete') {
      let fill = await filteringTransaction(data)
      setModalData(fill)
    }
    else { setModalData(data) }
    setModal(modal)
    setOpen(true);
  }

  const receiveTransaction = async (
    page,
    rowsPerPage,
    filterTransactionDateStart = null,
    filterTransactionDateEnd = null,
    filterDepartureDate = null,
    search,
    departureLabel
  ) => {
    try {
      setRows([])
      let response = await getTransactionApi(
        page,
        rowsPerPage,
        filterTransactionDateStart,
        filterTransactionDateEnd,
        filterDepartureDate,
        search,
        departureLabel
      )
      setTotalPage(response.data.data.meta.last_page)
      setRows(response.data.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const filteringTransaction = async (data) => {
    let filter = []
    rows.forEach((item) => {
      data.forEach((items) => {
        if (items == item.id) return filter.push(item)
      })
    })
    return filter
  }

  // filtter date
  const [sortDate, setSortDate] = useState('asc');
  const handleSortDate = () => {
    const sortedData = [...rows];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.transactionDate);
      const dateB = new Date(b.transactionDate);
      return sortDate === 'desc' ? dateA - dateB : dateB - dateA;
    });

    setRows(sortedData);
    setSortDate(sortDate === 'asc' ? 'desc' : 'asc');
  };

  // filtter status
  const [sortStatus, setSortStatus] = useState('asc');
  const handleSort = (handle) => {
    const sortedData = [...rows];

    if(handle == 'nama'){
      sortedData.sort((a, b) => {
        if (sortStatus === "asc") {
          return a.buyerName.localeCompare(b.buyerName);
        } else {
          return b.buyerName.localeCompare(a.buyerName);
        }
      });
    } else if (handle == 'status'){
      sortedData.sort((a, b) => {
        if (sortStatus === "asc") {
          return a.transactionStatus.localeCompare(b.transactionStatus);
        } else {
          return b.transactionStatus.localeCompare(a.transactionStatus);
        }
      });
    } else if(handle == 'ascending'){
      sortedData.sort((a, b) => {
        return sortStatus === "asc" ? a.id - b.id : b.id - a.id;
      });
    }

    setRows(sortedData);
    setSortStatus(sortStatus === 'asc' ? 'desc' : 'asc');
  };

  //react
  useEffect(() => {
    receiveTransaction(
      page,
      rowsPerPage,
      startTime,
      endTime,
      departureDate,
      search,
      departureLabel
    )
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Daftar Transaksi</h4>
            <p className={classes.cardCategoryWhite}>
              Daftar Transaksi
            </p>
          </CardHeader>
          <CardBody>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="start"
              alignItems="center"
              gap={2}
              marginBottom={2}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label="Tanggal Mulai Transaksi"
                    value={startTimeDayJs}
                    onChange={(newValue) => { setStartTimeDayJs(newValue); setStartTime(newValue ? newValue.format('YYYY-MM-DD') : null) }}
                  />
                  <DatePicker
                    label="Tanggal Berakhir Transaksi"
                    value={endTimeDayJs}
                    onChange={(newValue) => { setEndTimeDayJs(newValue); setEndTime(newValue ? newValue.format('YYYY-MM-DD') : null) }}
                  />
                </DemoContainer>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Tanggal Keberangkatan"
                    value={departureDateDayJs}
                    onChange={(newValue) => { setDepartureDateDayJs(newValue); setDepartureDate(newValue ? newValue.format('YYYY-MM-DD') : null) }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Button variant="contained" color="primary" onClick={handleFilter}>
                Cari
              </Button>
            </Stack>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              direction={{ xs: 'column', sm: 'row' }}
            >
              {/* Left */}
              <Stack direction="row" gap={3} alignItems="center">
                {/* Sort By */}
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Sort By:</Typography>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Transaction_date" onClick={handleSortDate}>Tgl Transaksi</ToggleButton>
                    <ToggleButton value="Status" onClick={() => handleSort("status")}>Status</ToggleButton>
                    <ToggleButton value="Nama pembeli" onClick={() => handleSort("nama")}>Nama Pembeli</ToggleButton>
                  </ToggleButtonGroup>
                  <SortIcon />
                </Stack>
                {/* End - Sort By */}
                {/* Asc | Dsc */}
                <Stack
                  direction='row'
                  alignItems="center"
                  onClick={() => handleSort("ascending")}
                >
                  <Typography>Desc</Typography>
                  <Switch />
                  <Typography>Asc</Typography>
                </Stack>
                {/* End - Asc | Dsc */}
              </Stack>
              {/* Right */}
              <Stack direction="row" alignItems="center" gap={2}>
                <SearchTable name="Cari Nama" onChange={handleSearch}/>
                <SearchTable name="Cari Rute Bus" onChange={handleDepartureLabel}/>
              </Stack>
            </Stack>
            <br />
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Tgl Transaksi</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Nama Pembeli</StyledTableCell>
                    <StyledTableCell>HP Pembeli</StyledTableCell>
                    <StyledTableCell>Rute Bus</StyledTableCell>
                    <StyledTableCell>Metode Pembayaran</StyledTableCell>
                    <StyledTableCell>Tgl Keberangkatan</StyledTableCell>
                    <StyledTableCell>Harga</StyledTableCell>
                    <StyledTableCell>Jumlah Tiket</StyledTableCell>
                    <StyledTableCell>Total Pembayaran</StyledTableCell>
                    <StyledTableCell>Biaya Admin</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.transactionDate).format("DD/MM/YYYY HH:mm:ss")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.transactionStatus}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.buyerName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.buyerPhone}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.busRoute}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.paymentType ?? "-"}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.departureDate).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {currencyFormatter(row.price)}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.ticketCount}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {currencyFormatter(row.grandTotal)}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.adminFee ? currencyFormatter(row.adminFee) : "Tidak Tersedia"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <DataSummaryIcon >
                          <div className="icon mr-1">
                            <PanToolAltOutlinedIcon onClick={() => handleOpen('detail', row)} />
                          </div>
                        </DataSummaryIcon>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" alignItems="center" sx={customPagination}>
              <Limit value={rowsPerPage} onChange={handleLimitPage} />
              <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      {open && <TransactionModal open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={() => { }} />}
    </GridContainer>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////


