/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Language from "@material-ui/icons/Language";
import ArticleIcon from "@mui/icons-material/Article";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import RouteIcon from "@mui/icons-material/Route";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PeopleIcon from '@mui/icons-material/People';
import DiscountIcon from '@mui/icons-material/Discount';
import PaidIcon from '@mui/icons-material/Paid';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import SettingPage from "views/Setting/Setting.js";
// import CityList from "views/DataList/CityList/City.jsx";
import CityAgent from "views/DataList/CityAgent/CityAgent";
import Transaction from "views/DataList/Transaction/Transaction";
import Passenger from "views/DataList/Passenger/Passenger";
import Bus from "views/DataList/Bus/Bus";
import Buyer from "views/DataList/Buyer/Buyer";
// import CityGroup from "views/DataList/RateList/CityGroup/CityGroup.jsx";
// import TambahCrew from "views/DataList/CrewList/CrewList.js";
import CrewList from "views/DataList/CrewList/CrewList.jsx";
import PromoList from "views/DataList/PromoList/PromoList.jsx";
import RateList from "views/DataList/RateList/RatePageH.js";
// import TambahAgen from "views/DataList/AgentList/AgentList";
// import AgenList from "views/DataList/AgentList/Agen";
import NewsArticle from "views/NewsArticles/NewsArticleList.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import SingInSide from "views/SignIn/SignInSide.js";
import Maps from "views/Maps/Maps.js";
import DeparturePage from "views/DataList/Departure/DepartureList"
import Role from "views/DataList/Role/Role"
import MeberList from "views/DataList/Member/MemberList/Member";

import Cms from "views/Cms/CmsForm"

const dashboardRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: Dashboard,
  //   component: DashboardPage,
  //   layout: "/admin",
  // },
  {
    path: "/departure",
    name: "Keberangkatan",
    rtlName: "لوحة القيادة",
    icon: DepartureBoardIcon,
    component: DeparturePage,
    layout: "/admin",
  },
  {
    path: "/member",
    name: "Member",
    rtlName: "لوحة القيادة",
    icon: PeopleIcon,
    component: MeberList,
    layout: "/admin",
  },
  {
    path: "/city-agent",
    name: "Kota & Agen",
    rtlName: "قائمة الجدول",
    icon: SupportAgentIcon,
    component: CityAgent,
    layout: "/admin",
  },
  {
    path: "/rate",
    name: "Rute dan Harga",
    rtlName: "قائمة الجدول",
    icon: RouteIcon,
    component: RateList,
    layout: "/admin",
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Setting",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: SettingPage,
    layout: "/admin",
  },
  {
    path: "/role",
    name: "Admin & Role",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: Role,
    layout: "/admin",
  },
  {
    path: "/data-pembeli",
    name: "Data Pembeli",
    rtlName: "قائمة الجدول",
    icon: EmojiPeopleIcon,
    component: Buyer,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Transaksi",
    rtlName: "قائمة الجدول",
    icon: PaidIcon,
    component: Transaction,
    layout: "/admin",
  },
  {
    path: "/bus",
    name: "Bus",
    rtlName: "قائمة الجدول",
    icon: DirectionsBusIcon,
    component: Bus,
    layout: "/admin",
  },
  {
    path: "/crew",
    name: "Crew",
    rtlName: "قائمة الجدول",
    icon: AirlineSeatReclineNormalIcon,
    component: CrewList,
    layout: "/admin",
  },
  {
    path: "/promo",
    name: "Promo",
    rtlName: "قائمة الجدول",
    icon: DiscountIcon,
    component: PromoList,
    layout: "/admin",
  },
  {
    path: "/newsarticle",
    name: "News and Articles",
    rtlName: "خرائط",
    icon: ArticleIcon,
    component: NewsArticle,
    layout: "/admin",
  },
  {
    path: "/cms",
    name: "CMS",
    rtlName: "خرائط",
    icon: ArticleIcon,
    component: Cms,
    layout: "/admin",
  },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin",
  // },
  {
    path: "/sign-in",
    name: "Sign In",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: SingInSide,
    layout: "/admin",
  },
];

export default dashboardRoutes;
