import React, {useState, useEffect} from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import {styleBox} from '../../../../utils/views/style'

// apis
import { deleteRoleApi, getRoleApi, getRoleApiById, saveRoleApi, updateRoleApi } from '../../../../apis/role';
import { getAbilityApi } from '../../../../apis/ability';
import { deleteAdminApi, saveAdminApi, updateAdminApi } from '../../../../apis/admin';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [dob, setDob] = useState('')
  const [gender, setGender] = useState('')
  const [password, setPassword] = useState('')
  const [roleId, setRoleId] = useState(1)

  const [dobDayJs, setDobDayJs] = useState(dayjs());

  const [rolesRows, setRolesRows] = useState([])
  const [selectedAbility, setSelectedAbility] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  
  // methods
  const handleClose = () => {
    setOpen(false)
  };

  const handleSaveAdmin = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      let response = await saveAdminApi({ name, email, phone, address, dob: dayjs(dobDayJs).format('YYYY-MM-DD'), gender, password, roleId: Number(roleId)})
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateRole = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      const body = { name, email, phone, address, dob: dayjs(dobDayJs).format('YYYY-MM-DD'), gender, password, roleId: Number(roleId)}
      if (password == '') {
        delete body.password
      }
      await updateAdminApi(modalData.id,body)
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteRole = async () => {
    try {
      setErrorMessage('')
      let response = await deleteAdminApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }

  const classes = useStyles();
  
  useEffect(() => {
    receiveRoles()
    if (modal != 'add') {
      setName(modalData.name)
      setEmail(modalData.email)
      setPhone(modalData.phone)
      setAddress(modalData.address)
      setDobDayJs(dayjs(modalData.dob))
      setGender(modalData.gender)
      setRoleId(modalData.roleId)
    }
  }, []);

  const receiveRoles = async () => {
    try {
      setRolesRows([])
      let response = await getRoleApi(1, 9999)
      response.data?.data.forEach(element => {
        setRolesRows(oldItem => [...oldItem, { id: element.id, label: element.label}])
      });
    } catch (error) {
      setErrorMessage(error)
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} 
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ? 
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Admin' : 'Update Admin'}
              </Typography>
              <Box overflow="auto" maxHeight="80vh" fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveAdmin : handleUpdateRole}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label="Nama"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    label="Email"
                    autoFocus
                    disabled={modal != 'add'}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    label="Phone"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    label="Alamat"
                    autoFocus
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimeField']}>
                      <DatePicker
                        label="Tanggal Lahir"
                        value={dobDayJs}
                        onChange={(v) => { setDobDayJs(v) }}
                        required
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <TextField
                    label="Jenis Kelamin"
                    select
                    value={gender}
                    fullWidth
                    onChange={(e) => setGender(e.target.value)}
                    style={{marginTop: '13px'}}
                    required
                  >
                    <MenuItem value="L">
                      Laki-laki
                    </MenuItem>
                    <MenuItem value="P">
                      Perempuan
                    </MenuItem>
                  </TextField>

                  <TextField
                    label="Role"
                    select
                    value={roleId}
                    fullWidth
                    onChange={(e) => setRoleId(e.target.value)}
                    style={{marginTop: '13px'}}
                    required
                  >
                    {
                      rolesRows.map((r) => (
                        <MenuItem value={r.id}>
                          {r.label}
                        </MenuItem>
                      ))
                    }
                  </TextField>

                  <TextField
                    margin="normal"
                    required={modal=='add'}
                    fullWidth
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    label="Password"
                    autoFocus
                    helperText={modal!='add'? "Biarkan kosong jika password tidak ingin diubah" : ''}
                  />
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ? 
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Admin dengan ID
                    { 
                      modalData.map((item)=>(
                        <span> {item.id}, </span>
                      ))
                    }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
            :
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Apakah Anda ingin menghapus Admin <span>{name}</span> ?
              </Typography>
              <center>
                <p>{errorMessage}</p>
              </center>
              <Box display="flex" justifyContent="end">
                <Button
                  color="outline"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {
                  !btnDelete ?
                    <Button
                      color="primary"
                      onClick={handleDeleteRole}
                    >
                      Delete
                    </Button>
                    : ''
                }
              </Box>
            </Box>
        }
        
      </Modal>
    </div>
  );
};

export default BasicModal;
