import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import AddRouteList from "./AddRouteModal.jsx";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Limit from "./../../../../components/Limit/Limit.js"
import SearchTable from "./../../../../components/SearchTable/SearchTable.js"

//apis
import { getRouteApi, bulkDeleteRouteApi } from '../../../../apis/routerate';
import { getCityApi } from '../../../../apis/city';

//Icon
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

// lib
import { useDebouncedCallback } from "use-debounce";

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  },
  justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "kotaAsal", headerName: "Kota Asal", width: 130 },
  { field: "kotaTujuan", headerName: "Kota Tujuan", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.kotaAsal || ""}_${params.row.kotaTujuan || ""}`,
  },
];

// const rows = [
//   { id: 1, kotaTujuan: "Palembang", kotaAsal: "Yogyakarta", age: 35 },
//   { id: 2, kotaTujuan: "Yogyakarta", kotaAsal: "Jember", age: 42 },
//   { id: 3, kotaTujuan: "Lannister", kotaAsal: "Jaime", age: 45 },
//   { id: 4, kotaTujuan: "Stark", kotaAsal: "Arya", age: 16 },
//   { id: 5, kotaTujuan: "Targaryen", kotaAsal: "Daenerys", age: null },
//   { id: 6, kotaTujuan: "Melisandre", kotaAsal: null, age: 150 },
//   { id: 7, kotaTujuan: "Clifford", kotaAsal: "Ferrara", age: 44 },
//   { id: 8, kotaTujuan: "Frances", kotaAsal: "Rossini", age: 36 },
//   { id: 9, kotaTujuan: "Roxie", kotaAsal: "Harvey", age: 65 },
// ];

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState('');
  const [modalData, setModalData] = useState({})
  const [rows, setRows] = useState([])
  const [totalPage, setTotalPage] = React.useState(1);
  //////
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedAll, setSelectedAll] = useState(false)
  const [routeDeleted, setRouteDeleted] = useState([])
  const [btnDeletedAll, setBtnDeletedAll] = useState(false)
  //////
  const [search, setSearch] = useState("");

    //methods
    const toggleCheckbox = (event) => {
      if (event.target.checked) {
        // setTrashStateBin([...trashState, event.target.value])
        setRouteDeleted([...routeDeleted, event.target.value])
      } else {
        // setTrashStateBin(currentValue => currentValue.filter((x) => x !== event.target.value))
        setRouteDeleted(currentValue => currentValue.filter((x) => x !== event.target.value))
      }
    }
    const toggleCheckboxAll = (event) => {
      let checkboxes = document.getElementsByName('check_list')
      if (event.target.checked) {
        let statePush = []
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
          statePush[i] = checkboxes[i].value
        }
        setSelectedAll(true)
        setRouteDeleted(statePush)
        // setTrashStateBin(statePush)
      } else {
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
          setSelectedAll(false)
          setRouteDeleted([])
          // setTrashStateBin([])
        }
      }
    }
  
    // const handleDeleteSingleOperator = (operatorId) => {
    //   setOperatorIdDelete(operatorId)
    //   setMessageDelete(`Are you sure you want to  delete this operator(s)?`)
    //   setOpenDeleteModal(true)
    // }
  
    const handleChangePage = async (event, newPage) => {
      setPage(newPage)
      await receiveRoute(newPage, rowsPerPage, search)
      // if (count >= 1) {
      //   setPage(newPage - 1);
      //   setCount(newPage)
      // }
    };

    const handleLimitPage = async (value) => {
      setRowsPerPage(value)
      setPage(1)
      await receiveRoute(1, value, search)
    }

    const handleSearch = useDebouncedCallback((value) => {
      setSearch(value)
      setPage(1)
      receiveRoute(1, rowsPerPage, value)
    }, 1000)

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event.target.value);
      setPage(0);
    };
    const handleOpen = (modal, data = {}) => {
      setModalData(data)
      setModal(modal)
      setOpen(true);
    }
    const handleReloadData = async () => {
      receiveRoute(page, rowsPerPage)
      setOpen(false)
      setBtnDeletedAll(false)
    }
  
    const receiveRoute = async (
      page, rowsPerPage, search
    ) => {
      try {
        setRows([])
        let row = await getRouteApi(
          page,rowsPerPage,search
        )
        setTotalPage(row.data.data.meta.last_page)
        row.data.data.data.forEach(element => {
          setRows(oldItem => [...oldItem, { id: element.id, 
            label: element.label, code:element.code, 
            rute: element.routeStops,
            route_id_admin: element.route_id_admin }])
        });
      } catch (error) {
        console.log(error)
      }
    }

    //react
    useEffect(() => {
      receiveRoute(page,rowsPerPage, search)
    }, []);
  
    useEffect(() => {
      if (selectedAll) {
        setBtnDeletedAll(true)
      } else {
        setBtnDeletedAll(false)
      }
    }, [selectedAll])
  
    useEffect(() => {
      if (routeDeleted.length >= 2) {
        setBtnDeletedAll(true)
      } else {
        setBtnDeletedAll(false)
      }
    }, [routeDeleted]);
  
  
    function test (val) {
      console.log(val)
    }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Daftar Rute</h4>
            <p className={classes.cardCategoryWhite}>
              Daftar Rute PO Putra Remaja
            </p>
          </CardHeader>
          <CardBody>
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2} marginBottom={2}>
            {btnDeletedAll ?
                <Button variant="contained" color="danger" onClick={() => handleOpen('bulkDelete', routeDeleted)}>
                  Bulk Delete
                </Button>
                : null
              }

            </Stack>
            <Stack direction="row" justifyContent="end" marginBottom={2}>
              <SearchTable name="Cari Nama Rute" onChange={handleSearch}/>
            </Stack>
            <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <input type="checkbox" onChange={toggleCheckboxAll} />
                      {/* <input type="checkbox" /> */}
                    </StyledTableCell>
                    <StyledTableCell>ID Rute</StyledTableCell>
                    <StyledTableCell>Nama Rute</StyledTableCell>
                    <StyledTableCell>Kode</StyledTableCell>
                    <StyledTableCell>Kota Tujuan</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <input
                          type="checkbox"
                          name="check_list"
                          onChange={toggleCheckbox}
                          value={row.id}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.label}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.code}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {
                          row.rute.map((element)=>(
                            <span>{element.city.label}, </span>
                          ))
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <DataSummaryIcon >
                          <div className="icon mr-1">
                            <EditOutlined onClick={() => handleOpen('update', row)} />
                          </div>
                          <div className="icon mr-1">
                            <DeleteOutlined onClick={() => handleOpen('delete', row)} />
                          </div>
                        </DataSummaryIcon>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
              <Limit value={rowsPerPage} onChange={handleLimitPage} />
              <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      {open && <AddRouteList open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={handleReloadData}/>}
    </GridContainer>
  );
}
