import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getMemberApi = async(
  page = 1, limit = 20, search
)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  url.searchParams.append('search', search);
  return await httpClientPrivate.get(`/buyers/users?${url.href.split('?')[1]}`, accessToken());
}