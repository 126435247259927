import React, { useEffect, useState,TextInput } from "react";
import dayjs from 'dayjs';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import DepartureModal from "./DepartureModal.jsx";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography } from "@material-ui/core";
import Switch from '@mui/material/Switch';
import SortIcon from '@mui/icons-material/Sort';
import Limit from "components/Limit/Limit.js";
import SearchTable from "components/SearchTable/SearchTable.js";

//apis
import { getDepartureApi } from '../../../apis/departure';

//Icon
import PanToolAltOutlinedIcon from '@mui/icons-material/PanToolAltOutlined';
import moment from 'moment';
import currencyFormatter from '../../../utils/currencyFormatter.js';
import TextField from '@mui/material/TextField';

// lib
import { useDebouncedCallback } from "use-debounce";

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E3E6E5',
        color: '#474A57',
        fontWeight: 700,
        fontSize: '16px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const customPagination = () => ({
    marginTop: '16px',
    width: '100%',
    '& .Mui-selected': {
        backgroundColor: '#FF0202 !important',
        color: 'white',
    },
    justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#247BA0',
}))
//////////////

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "kota", headerName: "Kota", width: 130 },
];


const useStyles = makeStyles(styles);

export default function TableList() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [modal, setModal] = useState('');
    const [modalData, setModalData] = useState({})
    const [rows, setRows] = useState([])
    //////
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [withFilter, setWithFilter] = React.useState(false);

    const [totalPage, setTotalPage] = React.useState(1);

    // Filter
    const [startTime, setStartTime] = useState(null)
    const [startTimeDayJs, setStartTimeDayJs] = useState(null);
    const [endTime, setEndTime] = useState(null)
    const [endTimeDayJs, setEndTimeDayJs] = useState(null);
    const [departureDate, setDepartureDate] = React.useState(null);
    const [departureDateDayJs, setDepartureDateDayJs] = useState(null);
    const [code, setCode] = useState('');
    //////

    // Continue create filter fields
    //methods
    const handleReloadData = async () => {
        await receiveDerpature(
            page,
            rowsPerPage,
            startTimeDayJs,
            endTimeDayJs,
            code
        )
        setOpen(false)
    }
    const handleFilter = async () => {
        setWithFilter(true)
        await receiveDerpature(
            1,
            rowsPerPage,
            startTime,
            endTime,
            code
        )
    }

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
        await receiveDerpature(
            newPage,
            rowsPerPage,
            startTime,
            endTime,
            code
        )
    };

    const handleLimitPage = async (value) => {
        setRowsPerPage(value)
        setPage(1)
        await receiveDerpature(
            1,
            value,
            startTime,
            endTime,
            code
        )
    }

    const handleCode = useDebouncedCallback((value) => {
        setCode(value)
        setPage(1)
        receiveDerpature(
            1,
            rowsPerPage,
            startTime,
            endTime,
            value
        )
       }, 1000)

    const handleOpen = async (modal, data = {}) => {
        if (modal == 'bulkDelete') {
            let fill = await filteringTransaction(data)
            setModalData(fill)
        }
        else { setModalData(data); }
        setModal(modal);
        setOpen(true);
    }

    const receiveDerpature = async (
        page,
        rowsPerPage,
        filterDepatureDateStart = null,
        filterDepatureDateEnd = null,
        code
    ) => {
        try {
            setRows([])
            let response = await getDepartureApi(
                page,
                rowsPerPage,
                filterDepatureDateStart ? dayjs(filterDepatureDateStart).format('YYYY-MM-DD') : null,
                filterDepatureDateEnd ? dayjs(filterDepatureDateEnd).format('YYYY-MM-DD') : null,
                code
            )
            setTotalPage(response.data.data.meta.last_page)
            setRows(response.data.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    const filteringTransaction = async (data) => {
        let filter = []
        rows.forEach((item) => {
            data.forEach((items) => {
                if (items == item.id) return filter.push(item)
            })
        })
        return filter
    }

    // filtter status
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (event, key) => {
      const newDate = new Date(event);
      if(key == 'start'){
        setStartDate(newDate);
      }

      if(key == 'end'){
        setEndDate(newDate);
      }
    };

    const handleSort = () => {
        const sortedData = [...rows];
        const tmpRows = [];

        sortedData.filter((item) => {
            const itemDate = new Date(item.departureDate);

            console.log(itemDate);
            if(itemDate >= startDate && itemDate <= endDate){
                tmpRows.push(item);
            }

            return (itemDate >= startDate && itemDate <= endDate);

        });

        setRows(tmpRows);
    }

    const [sortStatus, setSortStatus] = useState('asc');
    const handleSortAscDesc = (handle) => {
        const sortedData = [...rows];
        
        if(handle == 'ascending'){
            sortedData.sort((a, b) => {
                return sortStatus === "asc" ? a.id - b.id : b.id - a.id;
            });
        }
    
        setSortStatus(sortStatus === 'desc' ? 'asc' : 'desc');
        setRows(sortedData);
    };

    //react
    useEffect(() => {
        if(startDate == null && endDate == null){
            receiveDerpature(
                page,
                rowsPerPage,
                startTimeDayJs,
                endTimeDayJs,
                code
            )
        } 
    }, []);

    useEffect(() => {
        receiveDerpature(
            page,
            rowsPerPage,
            startTimeDayJs,
            endTimeDayJs,
            code
        )
    }, []);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Daftar Keberangkatan</h4>
                        <p className={classes.cardCategoryWhite}>
                            Daftar Keberangkatan
                        </p>
                    </CardHeader>
                    <CardBody>
                        <Stack mb={2} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" alignItems="center" gap={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                                        <DatePicker
                                            label="Tanggal Mulai"
                                            value={startTimeDayJs}
                                            onChange={(newValue) => { setStartTimeDayJs(newValue); setStartTime(newValue ? newValue.format('YYYY-MM-DD') : null);  handleDateChange(newValue, 'start'); }}
                                        />
                                        <DatePicker
                                            label="Tanggal Berakhir"
                                            value={endTimeDayJs}
                                            onChange={(newValue) => { setEndTimeDayJs(newValue); setEndTime(newValue ? newValue.format('YYYY-MM-DD') : null); setEndDate(newValue); handleDateChange(newValue, 'end'); }}
                                        />

                                    </DemoContainer>
                                </LocalizationProvider>
                                <Button 
                                    variant="contained"
                                    color="primary" 
                                    onClick={() => receiveDerpature(
                                        page,
                                        rowsPerPage,
                                        startTimeDayJs,
                                        endTimeDayJs,
                                        code
                                    )}
                                >
                                    Filter
                                </Button>
                            </Stack>

                        </Stack>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Typography>Sort:</Typography>
                                <Stack 
                                    direction='row'
                                    alignItems="center" 
                                    onClick={() => handleSortAscDesc("ascending")}
                                >
                                    <Typography>Desc</Typography>
                                    <Switch />
                                    <Typography>Asc</Typography>
                                </Stack>
                            </Stack>
                            <SearchTable name="Cari Kode" onChange={handleCode}/>
                        </Stack>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>ID</StyledTableCell>
                                        <StyledTableCell>Tanggal</StyledTableCell>
                                        <StyledTableCell>Waktu</StyledTableCell>
                                        <StyledTableCell>Kode</StyledTableCell>
                                        <StyledTableCell>Rute</StyledTableCell>
                                        <StyledTableCell>Seat Tersedia</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.id}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {moment(row.departureTime).format("DD/MM/YYYY")}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {moment(row.departureTime).format("HH:mm:ss")}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row.departureCode}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row.label}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row.availableSeat} / {row.totalSeat}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row.sellingStatus}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div>
                                                    {/* <span onClick={() => handleOpen('detail', row)} style={{ textDecoration: 'underline' }}>Detail</span> */}
                                                    <span onClick={() => handleOpen('edit', row)} style={{marginRight:'4px',marginLeft:'4px', textDecoration:'underline'}}>Ubah</span>
                                                    <span onClick={() => handleOpen('delete', row)} style={{textDecoration: 'underline' }}>Hapus</span>
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
                            <Limit value={rowsPerPage} onChange={handleLimitPage} />
                            <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                        </Box>
                    </CardBody>
                </Card>
            </GridItem>
            {open && <DepartureModal open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={handleReloadData} />}
        </GridContainer>
    );
}
////////////////////////////////////////////////////////////////////////////////////////////////


