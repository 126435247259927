import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getDepartureApi = async(
  page = 1, limit = 20, filterDepatureDateStart, filterDepartureDateEnd, code
)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(filterDepatureDateStart) {
    url.searchParams.append('filterDepartureDateStart', filterDepatureDateStart);
  }
  if(filterDepartureDateEnd) {
    url.searchParams.append('filterDepartureDateEnd', filterDepartureDateEnd);
  }
  url.searchParams.append('code', code);
  return await httpClientPrivate.get(`/departures?${url.href.split('?')[1]}`, accessToken());
}

export const getDepartureByIdApi = async (id) => {
  return await httpClientPrivate.get(`/departures/${id}`, accessToken());
}
export const saveDepartureApi = async (body) => {
  return await httpClientPrivate.post(`/departures`, body, accessToken());
}
export const updateDepartureApi = async (id, body) => {
  return await httpClientPrivate.put(`/departures/${id}`, body, accessToken());
}
export const removeDepartureApi = async (departureId) => {
  return await httpClientPrivate.delete(`/departures/${departureId}`, accessToken());
}