import React, { useState, useEffect } from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';

// costumize component
import Button from "components/CustomButtons/Button.js";

// mui tel-input
import { MuiTelInput } from 'mui-tel-input'

// utils 
import { styleBox } from '../../../../utils/views/style'

// apis
import { saveAgentApi, updateAgentApi, deleteAgentApi, bulkDeleteAgentApi } from "apis/agent";
import { getCityApi } from "apis/city";
import { element } from "prop-types";

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [agentName, setAgentName] = useState('')
  const [cityRows, setCityRows] = useState([])
  const [agentAddress, setAgentAddress] = useState('')
  const [agentMapUrl, setAgentMapUrl] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [phone, setPhone] = useState(0)
  const [selectCity, setSelectCity] = useState(0)
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  
  // methods
  const handleClose = () => {
    setOpen(false);
    if (modal == 'bulkDelete') setReloadData()
  }
  
  const handleChange = (newPhone) => {
    setPhone(newPhone)
  }
  const handleSaveAgent = async (event) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      let response = await saveAgentApi({ label: agentName, cityId: selectCity, address: agentAddress, phone: phone, mapUrl: agentMapUrl })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateAgent = async (event) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      let response = await updateAgentApi(modalData.id, { label: agentName, cityId: selectCity, address: agentAddress, phone: phone, mapUrl: agentMapUrl })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteAgent = async () => {
    try {
      setErrorMessage('')
      let response = await deleteAgentApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }

  const receiveCity = async () => {
    try {
      setErrorMessage('')
      let response = await getCityApi()
      response.data.data.forEach((element)=>{
        setCityRows((oldItem)=>[ ...oldItem, {value: element.id, label: element.label}])
      })
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
    }
  }

  const bulkDelete = async () => {
    try {
      let response = await bulkDeleteAgentApi({ ids: modalData })
      setReloadData()
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
      // if (error.response.data.data.agents) setErrorForeignMessage(error.response.data.data.agents)
    }
  }

  const classes = useStyles();

  useEffect(() => {
    receiveCity()
    if (modal != 'add') {
      setAgentName(modalData.agent)
      setAgentAddress(modalData.address)
      setAgentMapUrl(modalData.mapUrl)
      setSelectCity(modalData.city_id)
      setPhone(modalData.phone)
    }
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ?
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Agent' : 'Update Agent'}
              </Typography>
              <Box overflow="auto" maxHeight="80vh" fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveAgent : handleUpdateAgent}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select"
                    helperText="Please select city"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    value={selectCity}
                    onChange={e => setSelectCity(e.target.value)}
                    >
                    {cityRows.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    value={agentName}
                    onChange={e => setAgentName(e.target.value)}
                    id="agent"
                    label="Nama Agent"
                    autoComplete="nama Agent"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <TextField
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    id="phone-agent"
                    type="number"
                    label="Phone Number"
                    autoComplete="phone number"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <TextField
                    value={agentMapUrl}
                    onChange={e => setAgentMapUrl(e.target.value)}
                    id="agent"
                    label="Link Google Map"
                    autoComplete="Link Google Map"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <TextareaAutosize
                    aria-label="empty textarea"
                    placeholder="Agent address"
                    required
                    maxLength={256}
                    value={agentAddress}
                    onChange={(e) => setAgentAddress(e.target.value)}
                    style={{ marginTop: '16px', borderLeft: '1px solid grey', minWidth: '395px', maxWidth: '100%', height: "220px", maxHeight: "220px" }}
                  />
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ?
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Agent dengan ID {
                    modalData.map((item) => (
                      <span> {item}, </span>
                    ))
                  } ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                  {
                    errorForeignMessage.length > 0 ?
                      <p>List City:</p>
                      : null
                  }
                  {
                    errorForeignMessage.map((item) => (
                      <span>{item.label}, </span>
                    ))
                  }
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
            :
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Apakah Anda ingin menghapus Agent <span>{agentName}</span> ?
              </Typography>
              <center>
                <p>{errorMessage}</p>
              </center>
              <Box display="flex" justifyContent="end">
                <Button
                  color="outline"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {
                  !btnDelete ?
                    <Button
                      color="primary"
                      onClick={handleDeleteAgent}
                    >
                      Delete
                    </Button>
                    : ''
                }
              </Box>
            </Box>
        }

      </Modal>
    </div>
  );
};

export default BasicModal;
