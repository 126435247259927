import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getAdminApi = async(page = 1, limit = 20, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/admins?${url.href.split('?')[1]}`, accessToken());
}
export const getAdminApiById = async(AdminId)=>{
  return await httpClientPrivate.get(`/admins/${AdminId}`, accessToken());
}
export const saveAdminApi = async(body)=>{
  return await httpClientPrivate.post(`/admins`, body,accessToken());
}
export const updateAdminApi = async(id, body)=>{
  return await httpClientPrivate.put(`/admins/${id}`, body,accessToken());
}
export const deleteAdminApi = async(id)=>{
  return await httpClientPrivate.delete(`/admins/${id}`, accessToken());
}