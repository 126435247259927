export const styleBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: 'scroll',
  bgcolor: "background.paper",
  border: "1px solid white",
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};