import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getAgentApi = async(page = 1, limit = 20, search = "", isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  url.searchParams.append('search', search);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/agents?${url.href.split('?')[1]}`, accessToken());
}
export const saveAgentApi = async(body)=>{
  return await httpClientPrivate.post(`/agents`, body,accessToken());
}
export const updateAgentApi = async(id, body)=>{
  return await httpClientPrivate.put(`/agents/${id}`, body,accessToken());
}
export const deleteAgentApi = async(id)=>{
  return await httpClientPrivate.delete(`/agents/${id}`, accessToken());
}
export const bulkDeleteAgentApi = async(body)=>{
  return await httpClientPrivate.post(`/agents/delete`, body, accessToken());
}