/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import { roleId } from '../../apis/role';

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);
const roleUser = roleId()

const ShowList = (prop, key, props, classes, activePro, listItemClasses, whiteFontClasses) => {
  // return () => {
    return (
      <NavLink
      to={prop.layout + prop.path}
      className={activePro + classes.item}
      activeClassName="active"
      key={key}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          {typeof prop.icon === "string" ? (
            <Icon
              className={classNames(classes.itemIcon, whiteFontClasses, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            >
              {prop.icon}
            </Icon>
          ) : (
            <prop.icon
              className={classNames(classes.itemIcon, whiteFontClasses, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            />
          )}
          <ListItemText
            primary={props.rtlActive ? prop.rtlName : prop.name}
            className={classNames(classes.itemText, whiteFontClasses, {
              [classes.itemTextRTL]: props.rtlActive,
            })}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    );
  // }
}

const ShowMenu = (routes, props, classes, activeRoute, color) => {
  return (
    <List className={classes.list}>
    {routes.map((prop, key) => {
      // MAIN FUNCTION TO SHOW LIST OF ROUTES
      if(prop.path != '/user' &&
       prop.path != '/sign-in' &&
       prop.path !='/newsarticle' &&
       prop.path !='/setting'
       ) {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true,
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path),
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        switch(roleUser){
          case '1':
            return (
              ShowList(prop,
                key, props, classes,
                activePro, listItemClasses,
                whiteFontClasses)
            );
          case '2':
            if(prop.path != '/role'){
                return (
                  ShowList(prop,
                    key, props, classes,
                    activePro, listItemClasses,
                    whiteFontClasses)
                );
              }
          case '3':
            if(prop.path != '/bus' &&
              prop.path != '/city-agent' &&
              prop.path != '/rate' &&
              prop.path != '/user' &&
              prop.path != '/crew' &&
              prop.path != '/newsarticle' &&
              prop.path != '/role'){
              return (
                ShowList(prop,
                  key, props, classes,
                  activePro, listItemClasses,
                  whiteFontClasses)
              );
            }
          case '4':
            if(prop.path != '/bus' &&
            prop.path != '/departure' &&
            prop.path != '/city-agent' &&
            prop.path != '/rate' &&
            prop.path != '/user' &&
            prop.path != '/crew' &&
            prop.path != '/newsarticle' &&
            prop.path != '/cms' &&
            prop.path != '/role'){
              return (
                ShowList(prop,
                  key, props, classes,
                  activePro, listItemClasses,
                  whiteFontClasses)
              );
            }
          default:
            if(prop.path != '/bus' &&
            prop.path != '/departure' &&
            prop.path != '/city-agent' &&
            prop.path != '/rate' &&
            prop.path != '/user' &&
            prop.path != '/crew' &&
            prop.path != '/newsarticle' &&
            prop.path != '/cms' &&
            prop.path != '/role' &&
            prop.path != '/data-pembeli' &&
            prop.path != '/transaction' &&
            prop.path != '/promo') {
              return (
              ShowList(prop,
                key, props, classes,
                activePro, listItemClasses,
                whiteFontClasses)
            );
            }
        }
      }}
    )}
  </List>
  )
}

export default function Sidebar(props) {
  const classes = useStyles();
  let location = useLocation();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }
  const { color, logo, image, logoText, routes } = props;
  var links = (
      ShowMenu(routes, props, classes, activeRoute, color)
  );
  var brand = (
    <div className={classes.logo}>
      <a
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive,
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
