import { getLocalStorage } from "utils/localStorage"
import axios from 'axios';
import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getPassengerApi = async(
  page = 1, limit = 20, filterDepartureDateStart, filterDepartureDateEnd, search, originCityLabel, destinationCityLabel
)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(filterDepartureDateStart) {
    url.searchParams.append('filterDepartureDateStart', filterDepartureDateStart);
  }
  if(filterDepartureDateEnd) {
    url.searchParams.append('filterDepartureDateEnd', filterDepartureDateEnd);
  }
  url.searchParams.append('search', search);
  url.searchParams.append('originCityLabel', originCityLabel);
  url.searchParams.append('destinationCityLabel', destinationCityLabel);
  return await httpClientPrivate.get(`/passengers?${url.href.split('?')[1]}`, accessToken());
}

export const getPassengerDetailApi = async (id) => {
  return await httpClientPrivate.get(`/passengers/${id}`, accessToken());
}

export const downloadPassengerDataApi = async () => {
  return axios({
    url: `${process.env.REACT_APP_SERVER_URL}/api/passengers/today/download`,
    method: 'GET',
    headers:{
      Authorization: `Bearer ${getLocalStorage('access_token').token}`,
    },
    responseType: 'blob', // important
  })
}