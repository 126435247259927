import React, { useEffect, useState } from "react";
import moment from 'moment';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import AddPromoModal from "./AddPromoModal.jsx";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import currencyFormatter from '../../../utils/currencyFormatter.js';
import { Typography } from "@material-ui/core";
import Switch from '@mui/material/Switch';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

//apis
import { getCrewApi , bulkDeleteCityApi,  } from '../../../apis/crew';
import { getPromoApi } from '../../../apis/promo.js';

//Icon
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import SortIcon from '@mui/icons-material/Sort';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  }
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Kota", width: 130 },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];


const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState('');
  const [modalData, setModalData] = useState({})
  const [rows, setRows] = useState([])
  //////
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedAll, setSelectedAll] = useState(false)
  const [promoDeleted, setPromoDeleted] = useState([])
  const [btnDeletedAll, setBtnDeletedAll] = useState(false)
  const [alignment, setAlignment] = useState('Status');
  const [sortAsc, setSortAsc] = useState(true);


  //////


  //methods
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChangeOrientation = (event) => {
    setSortAsc(!event.target.value);
  };

  const toggleCheckbox = (event) => {
    if (event.target.checked) {
      // setTrashStateBin([...trashState, event.target.value])
      setPromoDeleted([...promoDeleted, event.target.value])
    } else {
      // setTrashStateBin(currentValue => currentValue.filter((x) => x !== event.target.value))
      setPromoDeleted(currentValue => currentValue.filter((x) => x !== event.target.value))
    }
  }
  const toggleCheckboxAll = (event) => {
    let checkboxes = document.getElementsByName('check_list')
    if (event.target.checked) {
      let statePush = []
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true
        statePush[i] = checkboxes[i].value
      }
      setSelectedAll(true)
      setPromoDeleted(statePush)
      // setTrashStateBin(statePush)
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
        setSelectedAll(false)
        setPromoDeleted([])
        // setTrashStateBin([])
      }
    }
  }

  // const handleDeleteSingleOperator = (operatorId) => {
  //   setOperatorIdDelete(operatorId)
  //   setMessageDelete(`Are you sure you want to  delete this operator(s)?`)
  //   setOpenDeleteModal(true)
  // }

  const handleChangePage = (event, newPage) => {
    if (count >= 1) {
      setPage(newPage - 1);
      setCount(newPage)
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleOpen = async (modal, data = {}) => {
    if (modal == 'bulkDelete') {
      let fill = await filteringPromo(data)
      setModalData(fill)
    }
    else { setModalData(data) }
    setModal(modal)
    setOpen(true);
  }
  const handleReloadData = async () => {
    setPromoDeleted([])
    receivePromo()
    setOpen(false)
    setBtnDeletedAll(false)
  }

  const receivePromo = async () => {
    try {
      setRows([])
      let response = await getPromoApi ()
      response.data.data.forEach(element => {
        setRows(oldItem => [...oldItem, { id: element.id, label: element.label, quota: element.quota, availableQuota: element.availableQuota, type: element.type, amount: element.amount, startTime: element.startTime, endTime: element.endTime, isActive: element.is_active }])
      });
    } catch (error) {
      console.log(error)
    }
  }
  
  const filteringPromo = async (data) => {
    let filter = []
    rows.forEach((item) => {
      data.forEach((items) => {
        if (items == item.id) return filter.push(item)
      })
    })
    return filter
  }

  // filtter status
  const [sortStatus, setSortStatus] = useState('desc');
  const handleSort = (handle) => {
    const sortedData = [...rows];

    if (handle == 'name') {
      sortedData.sort((a, b) => {
        if (sortStatus === "asc") {
          return a.label.localeCompare(b.label);
        } else {
          return b.label.localeCompare(a.label);
        }
      });
    } else if (handle == 'dateStart') {
      sortedData.sort((a, b) => {
        const dateA = new Date(a.startTime);
        const dateB = new Date(b.startTime);
        return sortDate === 'desc' ? dateA - dateB : dateB - dateA;
      });
    } else if (handle == 'dateEnd') {
      sortedData.sort((a, b) => {
        const dateA = new Date(a.endTime);
        const dateB = new Date(b.endTime);
        return sortDate === 'desc' ? dateA - dateB : dateB - dateA;
      });
    } else if (handle == 'ascending') {
      sortedData.sort((a, b) => {
        return sortStatus === "asc" ? a.id - b.id : b.id - a.id;
      });
    } else if (handle == 'status') {
      sortedData.sort((a, b) => {
        return sortStatus === "asc" ? a.isActive - b.isActive : b.isActive - a.isActive;
      });
    } 

    setRows(sortedData);
    setSortStatus(sortStatus === 'desc' ? 'asc' : 'desc');
  };

  //react
  useEffect(() => {
    receivePromo()
  }, []);

  useEffect(() => {
    if (selectedAll) {
      setBtnDeletedAll(true)
    } else {
      setBtnDeletedAll(false)
    }
  }, [selectedAll])

  useEffect(() => {
    if (promoDeleted.length >= 2) {
      setBtnDeletedAll(true)
    } else {
      setBtnDeletedAll(false)
    }
  }, [promoDeleted]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Daftar Promo</h4>
            <p className={classes.cardCategoryWhite}>
              Daftar Promo PO Putra Remaja
            </p>
          </CardHeader>
          <CardBody>
            <Stack
             justifyContent="end" 
             alignItems="center" 
             spacing={1}
             direction={{ xs: 'column', sm: 'row' }}
             >
              {btnDeletedAll ?
                <Button variant="contained" color="danger" onClick={() => handleOpen('bulkDelete', promoDeleted)}>
                  Bulk Delete
                </Button>
                : null
              }
              <Button variant="contained" color="primary" onClick={() => handleOpen('add')}>
                Tambah Promo
              </Button>
            </Stack>
            <Stack
             justifyContent="start" 
             alignItems="center" 
             spacing={1}
             direction={{ xs: 'column', sm: 'row' }}
            >
            <Typography>Sort By:</Typography>
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton onClick={() => handleSort("status")} value="Status">Status</ToggleButton>
                <ToggleButton onClick={() => handleSort("nama")} value="Nama promo">Nama promo</ToggleButton>
                <ToggleButton onClick={() => handleSort("dateStart")} value="Waktu mulai">Waktu mulai</ToggleButton>
                <ToggleButton onClick={() => handleSort("dateEnd")} value="Waktu berakhir">Waktu berakhir</ToggleButton>
              </ToggleButtonGroup>
              <SortIcon />
              <Stack 
                direction='row'
                alignItems="center" 
                
              >
                <Typography>Desc</Typography>
                <Switch defaultChecked />
                <Typography>Asc</Typography>
              </Stack>
            </Stack>

            <br/>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <input type="checkbox" onChange={toggleCheckboxAll} />
                      {/* <input type="checkbox" /> */}
                    </StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Nama Promo</StyledTableCell>
                    <StyledTableCell>Sisa Kuota</StyledTableCell>
                    <StyledTableCell>Jenis Promo</StyledTableCell>
                    <StyledTableCell>Nominal</StyledTableCell>
                    <StyledTableCell>Waktu Mulai</StyledTableCell>
                    <StyledTableCell>Waktu Berakhir</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <input
                          type="checkbox"
                          name="check_list"
                          onChange={toggleCheckbox}
                          value={row.id}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.label}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.availableQuota} dari {row.quota}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.type}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.type == 'discount' ? currencyFormatter(row.amount) : `${row.amount}%`}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.startTime).format("D/M/YYYY HH:mm:ss")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.endTime).format("D/M/YYYY HH:mm:ss")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.isActive ? 'Aktif': 'Tidak Aktif'}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <DataSummaryIcon >
                          <div className="icon mr-1">
                            <EditOutlined onClick={() => handleOpen('update', row)} />
                          </div>
                          <div className="icon mr-1">
                            <DeleteOutlined onClick={() => handleOpen('delete', row)} />
                          </div>
                        </DataSummaryIcon>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
              <Pagination count={rows.length / rowsPerPage <= 0 ? 1 : Math.ceil(rows.length / rowsPerPage)} page={count} onChange={handleChangePage} variant="outlined" shape="rounded" />
              {/* <div>
                <label className='mr-1'>show:</label>
                <select name="cars" id="cars" onChange={handleChangeRowsPerPage} style={{ borderRadius: '4px', height: '24px', padding: '4px 4px' }}>
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div> */}
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      {open && <AddPromoModal open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={handleReloadData} />}
    </GridContainer>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////


