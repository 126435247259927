import React, { useState, useEffect, Fragment } from "react";
import dayjs from 'dayjs';

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from "@material-ui/core/styles";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

// costumize component
import Button from "components/CustomButtons/Button.js";
import DepartureBusTemplate1 from './DepartureBusTemplate1'
import DepartureBusTemplate2 from './DepartureBusTemplate2'
import DepartureBusTemplate3 from './DepartureBusTemplate3'
import DepartureBusTemplate4 from './DepartureBusTemplate4'
import DepartureBusTemplate5 from './DepartureBusTemplate5'
import DepartureBusTemplate6 from './DepartureBusTemplate6'

// utils 
import { styleBox } from '../../../utils/views/style'

// apis
import { getDepartureByIdApi, saveDepartureApi, removeDepartureApi, updateDepartureApi } from '../../../apis/departure';
import { getRateGroupDefaultApi, getRouteApi, getRouteByIdApi } from '../../../apis/routerate';
import { getBusTypeApi, getBusApi } from '../../../apis/bus';
import { getCrewApi } from '../../../apis/crew';
import { getCityApi } from '../../../apis/city';
import moment from 'moment';
import { Stack } from '@mui/system';
import currencyFormatter from '../../../utils/currencyFormatter';
import { elementType } from "prop-types";

const stylesField = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    blackColor: {
        color: 'black'
    }
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
    // variable
    const [errorMsg, setErrorMsg] = useState('')
    const [seat, setSeat] = useState([])
    const [detailData, setDetailData] = useState([])
    const [departureCode, setDepartureCode] = useState('')
    const [departureRoute, setDepartureRoute] = useState('')
    const [departureDateDayJs, setDepartureDateDayJs] = useState(dayjs());
    const [departureEndDateDayJs, setDepartureEndDateDayJs] = useState(dayjs());
    const [departureTimeDayJs, setDepartureTimeDayJs] = useState(dayjs());
    const [busRows, setBusRows] = useState([]);
    const [routeRows, setRouteRows] = useState([]);
    const [routeStopRows, setRouteStopRows] = useState([]);
    const [busTypeRows, setBusTypeRows] = useState([]);
    const [busTypeRowsBackup, setBusTypeRowsBackup] = useState([]);
    const [crewRows, setCrewRows] = useState([]);
    const [sellingOption, setSellingOption] = useState([
        { id: 0, name: 'not_selling'},
        { id: 1, name: 'on_selling'},
        { id: 3, name: 'completed'}
    ])
    const [rateGroupRows, setRateGroupRows] = useState([]);
    const [selectBus, setSelectBus] = useState({});
    const [selectBusType, setSelectBusType] = useState(0);
    const [selectedRadioButton, setSelectedRadioButton] = useState('yes');
    const [busTypeName, setBusTypeName] = useState('');
    const [selectCrew, setSelectCrew] = useState([1]);
    const [selectCrewId, setSelectCrewId] = useState(0);
    const [selectSelling, setSelectSelling] = useState('');
    const [selectRoute, setSelectRoute] = useState({});
    const [city, setCity] = useState('');
    const classes = useStyles();

    // methods
    const handleSeat = (data) => {
        setSeat(data)
    }
    const removeCrewFields = (index) => {
        let data = [...selectCrew];
        data.splice(index, 1)
        setSelectCrew(data)
    }
    const handleClose = () => {
        setOpen(false)
    };
    const addCrewFields = () => {
        let data = ''
        setSelectCrew([...selectCrew, data])
    }
    const submitForm = async(event) =>{
        try {
            event.preventDefault()
            let transitTimesInput = []
            routeStopRows.forEach((element)=>{
                transitTimesInput.push({order: element.orderId, busTime: dayjs(element.time), busDay: element.busDay})
            })
            let body = {
                departureDate: selectedRadioButton == 'yes' ? dayjs(departureDateDayJs).format('YYYY-MM-DD') : null,
                departureStartDate: selectedRadioButton == 'no' ? dayjs(departureDateDayJs).format('YYYY-MM-DD') : null,
                departureEndDate: selectedRadioButton == 'no' ? dayjs(departureEndDateDayJs).format('YYYY-MM-DD') : null,
                routeId: selectRoute.id,
                busId: selectBus.id,
                busTypeId: selectBusType,
                sellingStatus: selectSelling,
                code: departureCode,
                label: selectRoute.label,
                crewIds: selectCrew,
                transitTimes: transitTimesInput,
                seats: seat
            }
            let response = await saveDepartureApi(body)
            setReloadData()
        } catch (error) {
            console.log(error)
        }
    }
    const updateForm = async(event) =>{
        try {
            event.preventDefault()
            let transitTimesInput = []
            routeStopRows.forEach((element) => {
                // transitTimesInput.push({ order: element.orderId, time: dayjs(element.time).format('HH:mm') })
                transitTimesInput.push({ order: element.orderId, busTime: dayjs(element.time), busDay: element.busDay })
            })
            let body = {
                busId: selectBus.id,
                busTypeId: selectBusType,
                sellingStatus: selectSelling,
                label: departureRoute,
                crewIds: selectCrew,
                transitTimes: transitTimesInput,
                seats: seat
            }
            let response = await updateDepartureApi(modalData.id, body)
            setReloadData()
        } catch (error) {
            setErrorMsg(error.response.data.errors[0].message)
            console.log(error)
        }
    }
    const handleFormCrewChange = (index, event) => {
        let data = [...selectCrew];
        data[index] = event.target.value;
        setSelectCrew(data);
    }
    const handleFormTimeChange = (index, event) => {
        let data = [...routeStopRows];
        data[index]['time'] = dayjs(event)
        setRouteStopRows(data);
    }
    const handleFormBusDayChange = (index, event) => {
        let data = [...routeStopRows];
        data[index]['busDay'] = Number(event.target.value)
        setRouteStopRows(data);
    }
    const handleCityLabel = (cityId) => {
        let cityLabel =[]
        city.forEach((element) => {
            if (cityId==element.id){
                return cityLabel.push(element.label)
            }
        })
        return cityLabel
    }

    // api
    const handleDeleteDeparture = async() => {
        try {
            let response = await removeDepartureApi(modalData.id)
            setReloadData()
        } catch (error) {
            console.log(error)
        }
    }
    const receiveDepartureDetail = async () => {
        try {
            let response = await getDepartureByIdApi(modalData.id)
            setDetailData(response.data.data)
            response.data.data.crews.forEach((element)=>{
                setSelectCrew(oldItem => [...oldItem, element.id])
            })
            response.data.data.transits.forEach(element => {
                setRouteStopRows(oldItem => [...oldItem, { orderId: element.order, time: dayjs(element.busTime), cityLabel: element.cityLabel, busDay: element.busDay }])
                // setRouteStopRows(oldItem => [...oldItem, { orderId: element.order, time: element.time, cityLabel: element.cityLabel }])
            });
        } catch (error) {
            console.log(error)
        }
    }
    const receiveCity = async () => {
        try {
            let response = await getCityApi()
            setCity(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const receiveBusType = async () => {
        try {
            setBusTypeRows([])
            setBusTypeRowsBackup([])
            let response = await getBusTypeApi()
            response.data.data.forEach(element => {
                if(selectBus.busType.id == element.id){
                    setBusTypeRows(oldItem => [...oldItem, { id: element.id, code: element.code, label: element.label, totalSeat: element.totalSeat }])
                }
                setBusTypeRowsBackup(oldItem => [...oldItem, { id: element.id, code: element.code, label: element.label, totalSeat: element.totalSeat }])
            });
        } catch (error) {
            console.log(error)
            // setErrorMessage(error)
        }
    }

    const receiveCrew = async () => {
        try {
            setCrewRows([])
            let response = await getCrewApi()
            response.data.data.forEach(element => {
                setCrewRows(oldItem => [...oldItem, { id: element.id, name: element.name, jobTitle: element.jobTitle, phone: element.phone }])
            });
        } catch (error) {
            console.log(error)
        }
    }

    const receiveRateGroupDefault = async () => {
        try {
            setRateGroupRows([])
            let response = await getRateGroupDefaultApi()
            // response = JSON.parse(response)
            response.data.data.data.forEach(element => {
                setRateGroupRows(oldItem => [...oldItem, { id: element.id, label: element.label, depcity: element.departureGroup.label, descity: element.destinationGroup.label, bustype: element.busType.label, depdate: element.departure_date, price: element.price }])
            });
        } catch (error) {
            console.log(error)
        }
    }
    
    const receiveBus = async () =>{
        try {
            setBusRows([])
            let response = await getBusApi()
            // response = JSON.parse(response)
            response.data.data.forEach(element => {
                setBusRows(oldItem => [...oldItem, { id: element.id, label: element.label, busType: element.busType}])
            });
        } catch (error) {
            console.log(error)
        }
    }
    const receiveRoute = async () =>{
        try {
            setRouteRows([])
            let response = await getRouteApi(1,9999)
            // response = JSON.parse(response)
            response.data.data.data.forEach(element => {
                setRouteRows(oldItem => [...oldItem, { id: element.id, label: element.label}])
            });
        } catch (error) {
            console.log(error)
        }
    }
    const receiveRouteById = async (routeId) =>{
        try {
            setRouteStopRows([])
            let response = await getRouteByIdApi(routeId)
            response.data.data.routeStops.forEach(element => {
                setRouteStopRows(oldItem => [...oldItem, { orderId: element.order, time: element.busTime ? dayjs(element.busTime) : dayjs(), cityId: element.cityId, busDay: element.busDay }])
            });
        } catch (error) {
            console.log(error)
        }
    }
    const filterSelectBusType = async (busTypeId) =>{
        try{
            let result = busTypeRows.filter((element)=>{
                return element.id == busTypeId
            })
            setBusTypeName(result[0].label)
        }catch(error){

        }
    }
    const handleChangeRadioButton = (ev) =>{
        setSelectedRadioButton(ev.target.value)
    }

    useEffect(async () => {
        await receiveCrew()
        // await receiveRateGroupDefault()
        await receiveBus()
        await receiveRoute()
        await receiveCity()
        await receiveBusType()
        if(modal != 'add'){
            await receiveDepartureDetail()
            setDepartureDateDayJs(dayjs(modalData.departureDate))
            setDepartureTimeDayJs(dayjs(modalData.departureDate))
            setDepartureCode(modalData.departureCode)
            setDepartureRoute(modalData.label)
            setSelectSelling(modalData.sellingStatus)
            setSelectBus(modalData.busLabel ? {id:1, label: modalData.busLabel, busType:{id: modalData.busTypeId, code: modalData.busTypeCode, label: modalData.busTypeLabel}} : 'Bus 1' )
            setSelectBusType(modalData.busTypeId)
        }
    }, []);
    useEffect(async() => {
        await receiveRouteById(selectRoute.id)
        console.log(selectRoute,"ini select route")
    }, [selectRoute])
    useEffect(()=>{
        if (modal == 'edit') {   
            filterSelectBusType(modalData.busTypeId)
        }
    },[busTypeRowsBackup])
    useEffect(async() => {
            await receiveBusType()
    }, [selectBus])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                {
                    modal != 'delete' ?
                
                <Box sx={{ ...styleBox, width: '60vw', height:'70vh', overflow:'scroll' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {
                            modal == 'add' ? 'Buat Keberangkatan' : 'Ubah Data Keberangkatan'
                        }
                    </Typography>
                        <form onSubmit={event => modal == 'add' ? submitForm(event) : updateForm(event) }>
                    <Grid container spacing={1} alignItems="start">
                        <Grid item xs={7} sx={{ overflow: 'none !important' }}>
                            <Box maxHeight="80vh" fullWidth>
                                    {   modal == 'add' ?
                                            <div>
                                                <label>Buat Lebih dari satu jadwal keberangkatan?</label>
                                                <RadioGroup onChange={handleChangeRadioButton} value={selectedRadioButton}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={4}>
                                                            <FormControlLabel value="yes" control={<Radio />} label="Single Date" />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControlLabel value="no" control={<Radio />} label="Range Date" />
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                            </div>
                                        : null
                                    }
                                    {
                                        selectedRadioButton == 'yes' ?
                                            <Grid container spacing={2} mt={1}>
                                                <Grid item xs={6}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                                                            <DatePicker
                                                                label="Tanggal Keberangkatan"
                                                                value={departureDateDayJs}
                                                                disabled={modal == 'edit' ? true : false}
                                                                require
                                                                onChange={(newValue) => { setDepartureDateDayJs(newValue) }}
                                                                />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        :
                                            <div>
                                                <Grid container spacing={2} mt={1}>
                                                    <Grid item xs={6}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                                                                <DatePicker
                                                                    label="Tanggal Awal Keberangkatan"
                                                                    value={departureDateDayJs}
                                                                    disabled={modal == 'edit' ? true : false}
                                                                    require
                                                                    onChange={(newValue) => { setDepartureDateDayJs(newValue) }}
                                                                    />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                                                                <DatePicker
                                                                    label="Tanggal Akhir Keberangkatan"
                                                                    value={departureEndDateDayJs}
                                                                    disabled={modal == 'edit' ? true : false}
                                                                    require
                                                                    onChange={(newValue) => { setDepartureEndDateDayJs(newValue) }}
                                                                    />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                    }
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                margin="dense"
                                                disabled={modal == 'edit' ? true : false}
                                                fullWidth
                                                value={departureCode}
                                                onChange={e => setDepartureCode(e.target.value)}
                                                label="Kode Keberangkatan"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                                                    <TimePicker
                                                        label="Jam Keberangkatan"
                                                        value={departureTimeDayJs}
                                                        disabled={modal == 'edit' ? true : false}
                                                        onChange={(newValue) => { setDepartureTimeDayJs(newValue) }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {
                                                modal == 'edit' ?
                                                    <TextField
                                                        margin="dense"
                                                        disabled={modal == 'edit' ? true : false}
                                                        fullWidth
                                                        value={departureRoute}
                                                        // onChange={e => setDepartureRoute(e.target.value)}
                                                        label="Rute"
                                                    />
                                                : 
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Rute"
                                                        margin="dense"
                                                        fullWidth
                                                        value={selectRoute}
                                                        onChange={e => setSelectRoute(e.target.value)}
                                                    >
                                                        {routeRows.map((option, index) => (
                                                            <MenuItem key={option.id} value={option}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Status Penjualan"
                                                margin="dense"
                                                fullWidth
                                                value={selectSelling}
                                                onChange={e => setSelectSelling(e.target.value)}
                                            >
                                                {sellingOption.map((option, index) => (
                                                    <MenuItem key={option.id} value={option.name}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {
                                                modal == 'edit' ?
                                                    <TextField
                                                        margin="dense"
                                                        disabled={modal == 'edit' ? true : false}
                                                        fullWidth
                                                        value={selectBus.label}
                                                        label="Bus"
                                                    />
                                                            :
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Bus"
                                                        margin="dense"
                                                        fullWidth
                                                        value={selectBus}
                                                        onChange={e => setSelectBus(e.target.value)}
                                                    >
                                                        {
                                                            busRows.map((option, index) => (
                                                                <MenuItem key={option.id} value={option}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            {

                                                modal == 'edit' ?
                                                    <TextField
                                                        margin="dense"
                                                        disabled={modal == 'edit' ? true : false}
                                                        fullWidth
                                                        value={busTypeName}
                                                        label="Tipe Bus"
                                                    />
                                                    :
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Tipe Bus"
                                                        margin="dense"
                                                        disabled={modal == 'edit' ? true : false}
                                                        fullWidth
                                                        value={selectBusType}
                                                        onChange={e => setSelectBusType(e.target.value)}
                                                    >
                                                        {busTypeRows.map((option, index) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {
                                                selectCrew.map((item,index)=>(
                                                    <div>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            label="Crew"
                                                            margin="dense"
                                                            fullWidth
                                                            value={item}
                                                            key={index}
                                                            required
                                                            onChange={event => handleFormCrewChange(index,event)}
                                                        >
                                                            {crewRows.map((option, index) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                        {
                                                            selectCrew.length > 1 ?
                                                                <Button variant="contained" color="danger" onClick={()=>removeCrewFields(index,event)}>
                                                                    Remove
                                                                </Button>
                                                            : null
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={6} mt={1}>
                                            <Button type="button" variant="contained" color="success" onClick={addCrewFields}>
                                                Add Crew
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <Grid item xs={12}>
                                            {
                                                routeStopRows.length != 0 ?
                                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                                        Route stop / Bus Day / Transit Times
                                                    </Typography>
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        routeStopRows.map((element,index)=>(
                                            <Grid container spacing={2} key={element.orderId} alignItems="center">
                                                <Grid item xs={4}>
                                                    {
                                                        modal == 'add' ?
                                                            <p>Kota {handleCityLabel(element.cityId)}</p>
                                                        :
                                                            <p>Kota {element.cityLabel}</p>
                                                    }
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField 
                                                        type="number" 
                                                        label="Bus Day"
                                                        inputProps={{ min: 1 }}
                                                        value={element.busDay}
                                                        onChange={event => handleFormBusDayChange(index, event)}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                                                            <TimePicker
                                                                ampm={false}
                                                                label="Jam Transit"
                                                                value={element.time}
                                                                key={index}
                                                                onChange={event => handleFormTimeChange(index, event)}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                    
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            {
                                selectBusType == 1 ?
                                    <DepartureBusTemplate1 seats={detailData.seats ? detailData.seats : []} updateSeat={handleSeat} />
                                    :
                                selectBusType == 2 ?
                                    <DepartureBusTemplate2 seats={detailData.seats ? detailData.seats : []} updateSeat={handleSeat} />
                                    :
                                selectBusType == 3 ?
                                    <DepartureBusTemplate3 seats={detailData.seats ? detailData.seats : []} updateSeat={handleSeat} />
                                    :
                                selectBusType == 4 ?
                                    <DepartureBusTemplate6 seats={detailData.seats ? detailData.seats : []} updateSeat={handleSeat} />
                                    :
                                selectBusType == 5 ?
                                    <DepartureBusTemplate5 seats={detailData.seats ? detailData.seats : []} updateSeat={handleSeat} />
                                    :
                                selectBusType == 6 ?
                                    <DepartureBusTemplate4 seats={detailData.seats ? detailData.seats : []} updateSeat={handleSeat} />
                                    :
                                null
                            }
                        </Grid>
                    </Grid>
                    <Grid mt={2} container spacing={8}>
                        {
                            errorMsg.length >=1 ?
                                <Grid item xs={12} display="flex" justifyContent="end" alignItems="center">
                                    <p style={{color: 'red', fontWeight: 600}}>{errorMsg}</p>
                                </Grid>
                            :null
                        }
                    </Grid>
                    <Grid mt={2} container spacing={2}>
                        <Grid item xs={12} display="flex" justifyContent="end" alignItems="end">
                            <Button type="button" color="outline" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                Save Keberangkatan
                            </Button>
                        </Grid>
                    </Grid>
                    </form>
                </Box>
                : 
                    <Box sx={{ ...styleBox, width: 400 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Apakah Anda ingin menghapus Keberangkatan ?
                        </Typography>
                        <center>
                            {/* <p>{errorMessage}</p> */}
                        </center>
                        <Box display="flex" justifyContent="end">
                            <Button
                                color="outline"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                onClick={handleDeleteDeparture}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
            }
            </Modal>
        </div>
    );
};

export default BasicModal;
