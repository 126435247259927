import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import AddBusTypeModal from "./AddBusTypeModal.jsx";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Limit from "components/Limit/Limit.js";

//apis
import { getBusTypeApi } from '../../../../apis/bus.js';

//Icon
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  },
  justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "kota", headerName: "Kota", width: 130 },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  // },
];


const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState('');
  const [modalData, setModalData] = useState({})
  const [rows, setRows] = useState([])
  //////
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [totalPage, setTotalPage] = React.useState(1);
  const [selectedAll, setSelectedAll] = useState(false)
  const [agentDeleted, setAgentDeleted] = useState([])
  const [btnDeletedAll, setBtnDeletedAll] = useState(false)
  //////


  //methods
  const toggleCheckbox = (event) => {
    if (event.target.checked) {
      // setTrashStateBin([...trashState, event.target.value])
      setAgentDeleted([...agentDeleted, event.target.value])
    } else {
      // setTrashStateBin(currentValue => currentValue.filter((x) => x !== event.target.value))
      setAgentDeleted(currentValue => currentValue.filter((x) => x !== event.target.value))
    }
  }
  const toggleCheckboxAll = (event) => {
    let checkboxes = document.getElementsByName('check_list')
    if (event.target.checked) {
      let statePush = []
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true
        statePush[i] = checkboxes[i].value
      }
      setSelectedAll(true)
      setAgentDeleted(statePush)
      // setTrashStateBin(statePush)
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
        setSelectedAll(false)
        setAgentDeleted([])
        // setTrashStateBin([])
      }
    }
  }

  // const handleDeleteSingleOperator = (operatorId) => {
  //   setOperatorIdDelete(operatorId)
  //   setMessageDelete(`Are you sure you want to  delete this operator(s)?`)
  //   setOpenDeleteModal(true)
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    receiveBusType(newPage, rowsPerPage)
  };

  const handleLimitPage = async (value) => {
    setRowsPerPage(value)
    setPage(1)
    await receiveBusType(1, value)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  const handleOpen = (modal, data = {}) => {
    setModalData(data)
    setModal(modal)
    setOpen(true);
  }
  const handleReloadData = async () => {
    setAgentDeleted([])
    receiveBusType(page, rowsPerPage)
    setOpen(false)
    setBtnDeletedAll(false)
  }

  const receiveBusType = async (page, rowsPerPage) => {
    try {
      setRows([])
      let response = await getBusTypeApi(page, rowsPerPage, true)
      setTotalPage(response.data.data.meta.last_page)
      response.data.data.data.forEach(element => {
        setRows(oldItem => [...oldItem, { id: element.id, code: element.code, label: element.label, totalSeat: element.totalSeat }])
      });
    } catch (error) {
      console.log(error)
    }
  }

  //react
  useEffect(() => {
    receiveBusType(page, rowsPerPage)
  }, []);

  useEffect(() => {
    if (selectedAll){
      setBtnDeletedAll(true)
    }else{
      setBtnDeletedAll(false)
    }
  }, [selectedAll])

  useEffect(() => {
    if (agentDeleted.length >= 2) {
      setBtnDeletedAll(true)
    }else{
      setBtnDeletedAll(false)
    }
  }, [agentDeleted]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Daftar Tipe Bus</h4>
            <p className={classes.cardCategoryWhite}>
              Daftar Tipe Bus PO Putra Remaja
            </p>
          </CardHeader>
          <CardBody>
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={1}>
              { btnDeletedAll ?
                <Button variant="contained" color="danger" onClick={() => handleOpen('bulkDelete', agentDeleted)}>
                    Bulk Delete
                  </Button>
                : null
              }
              {/* User not allowed to add bus type */}
              {/* <Button variant="contained" color="primary" onClick={() => handleOpen('add')}>
                Tambah Tipe Bus
              </Button> */}
            </Stack>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <input type="checkbox" onChange={toggleCheckboxAll} />
                      {/* <input type="checkbox" /> */}
                    </StyledTableCell>
                    <StyledTableCell >ID Tipe Bus</StyledTableCell>
                    <StyledTableCell >Kode Tipe Bus</StyledTableCell>
                    <StyledTableCell >Nama Tipe Bus</StyledTableCell>
                    <StyledTableCell >Jumlah Kursi</StyledTableCell>
                    <StyledTableCell >Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <input
                          type="checkbox"
                          name="check_list"
                          onChange={toggleCheckbox}
                          value={row.id}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell >
                        {row.code}
                      </StyledTableCell>
                      <StyledTableCell >
                        {row.label}
                      </StyledTableCell>
                      <StyledTableCell >
                        {row.totalSeat}
                      </StyledTableCell>
                      <StyledTableCell >
                        <DataSummaryIcon >
                          <div className="icon mr-1">
                            <EditOutlined onClick={() => handleOpen('update', row)} />
                          </div>
                          <div className="icon mr-1">
                            <DeleteOutlined onClick={() => handleOpen('delete', row)} />
                          </div>
                        </DataSummaryIcon>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
              <Limit value={rowsPerPage} onChange={handleLimitPage} />
              <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
              {/* <div>
                <label className='mr-1'>show:</label>
                <select name="cars" id="cars" onChange={handleChangeRowsPerPage} style={{ borderRadius: '4px', height: '24px', padding: '4px 4px' }}>
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div> */}
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      {open && <AddBusTypeModal open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={handleReloadData} />}
    </GridContainer>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////


