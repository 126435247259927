import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from 'react-redux'
import { loginApi } from '../../apis/login'
import { getRoleApi } from '../../apis/role'
import { setLocalStorage, getLocalStorage } from '../../utils/localStorage'
import OneSignal from "react-onesignal";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://putraremaja.com/">
        PO Putra Remaja
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {
  const history = useHistory();
  const {product}= useSelector((state) => state.product)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [notifId, setNotifId] = useState('')

  const receiveAbility = async() => {
    try{
      let response = await getRoleApi()
    }catch(error){

    }
  }
  const handleSubmit = async(event) => {
    try{
      event.preventDefault();
      setErrorMessage('')
      let body = {
        notificationId: notifId,
        email,
        password
      }
      let response = await loginApi(body)
      setLocalStorage('access_token', response.data.data.oat)
      setLocalStorage('roleId', response.data.data.roleId)
      location.href='/admin/data-pembeli'
    }catch(error){
      console.log(error)
    }
  };

  useEffect(()=>{
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_ID
    })
  },[])

  useEffect(()=>{
    OneSignal.isPushNotificationsEnabled((isEnabled)=>{
      if(isEnabled){
        OneSignal.getUserId((userId)=>{
          // console.log("player_id of the subscribed user is : " + userId);
          setNotifId(userId)
        })
      }
    })
  },[])

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              noValidate
              sx={{ mt: 1 }}
            >
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Grid
                  container
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <center>
                      <p className='m-0 mt-2 font-weight-bolder text-center'>
                        {errorMessage}
                      </p>
                    </center>
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  >
                  Sign In
                </Button>
              </form>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
