import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import AddRateGroupCustomsList from "./AddRateCustomModal";
import { Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import currencyFormatter from '../../../../utils/currencyFormatter.js';
import Limit from "components/Limit/Limit.js";

//apis
import { getRateGroupCustomsApi, bulkDeleteRateGrouppCustomsApi } from '../../../../apis/routerate.js';

//Icon
import EditOutlined from '@mui/icons-material/EditOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { colors } from "@mui/material";

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  },
  justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "groupKotaAsal", headerName: "Klpk k.Asal", width: 130 },
  { field: "groupKotaTujuan", headerName: "Klpk k.Tujuan", width: 130 },
  {
    field: "jurusan",
    headerName: "Jurusan",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.groupKotaAsal || ""}_${params.row.groupKotaTujuan || ""}`,
  },
  {
    field: "hargaRute",
    headerName: "Harga",
    type: "number",
    width: 90,
  },
];

// const rows = [
//   {
//     id: 1,
//     groupKotaTujuan: "Palembang",
//     groupKotaAsal: "Jateng-DIY",
//     hargaRute: 35,
//   },
//   {
//     id: 2,
//     groupKotaTujuan: "Jateng-DIY",
//     groupKotaAsal: "Jatim",
//     hargaRute: 42,
//   },
//   {
//     id: 3,
//     groupKotaTujuan: "Lampung",
//     groupKotaAsal: "Jateng-DIY",
//     hargaRute: 45,
//   },
// ];

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = useState('');
  const [modalData, setModalData] = useState({})
  const [rows, setRows] = useState([])
  const [withFilter, setWithFilter] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(1);
  //////
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedAll, setSelectedAll] = useState(false)
  const [RateGroupCustomsDeleted, setRateGroupCustomsDeleted] = useState([])
  const [btnDeletedAll, setBtnDeletedAll] = useState(false)
  //////

    //methods

    const handleFilter = async () => {
      setWithFilter(true)
      await receiveRateGroupCustom(page, rowsPerPage, departureDate, departureDateEnd)
    }

    const receiveRateGroupCustom = async(
      page, rowsPerPage, filterDepartureDateStart = null, filterDepartureDateEnd = null
      ) =>{
      try {
        setRows([])
        console.log('filterDepartureDate=', filterDepartureDateStart)
        let response = await getRateGroupCustomsApi(
          page, rowsPerPage, filterDepartureDateStart, filterDepartureDateEnd
        )
        setTotalPage(response.data.data.meta.last_page)
        // setRows(response.data.data.data)
        response.data.data.data.forEach(element => {
          setRows(oldItem => [...oldItem, { 
            id: element.id, label: element.label, 
            depcity_id: element.departureGroup.id,
            depcity: element.departureGroup.label, 
            descity_id: element.destinationGroup.id,
            descity: element.destinationGroup.label,
            busid: element.busType.id,
            bustype: element.busType.label, 
            depdateStart: element.departure_start_date, 
            depdateEnd: element.departure_end_date, 
            price: element.price }])
        })
      } catch (error) {
        console.log(error)
      }
    }
  
    const filteringRateCustom = async (data) => {
      let filter = []
      rows.forEach((item) => {
        data.forEach((items) => {
          if (items == item.id) return filter.push(item)
        })
      })
      return filter
    }
  

    const toggleCheckbox = (event) => {
      if (event.target.checked) {
        // setTrashStateBin([...trashState, event.target.value])
        setRateGroupCustomsDeleted([...RateGroupCustomsDeleted, event.target.value])
      } else {
        // setTrashStateBin(currentValue => currentValue.filter((x) => x !== event.target.value))
        setRateGroupCustomsDeleted(currentValue => currentValue.filter((x) => x !== event.target.value))
      }
    }
    const toggleCheckboxAll = (event) => {
      let checkboxes = document.getElementsByName('check_list')
      if (event.target.checked) {
        let statePush = []
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
          statePush[i] = checkboxes[i].value
        }
        setSelectedAll(true)
        setRateGroupCustomsDeleted(statePush)
        // setTrashStateBin(statePush)
      } else {
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
          setSelectedAll(false)
          setRateGroupCustomsDeleted([])
          // setTrashStateBin([])
        }
      }
    }
  
    // const handleDeleteSingleOperator = (operatorId) => {
    //   setOperatorIdDelete(operatorId)
    //   setMessageDelete(`Are you sure you want to  delete this operator(s)?`)
    //   setOpenDeleteModal(true)
    // }
  
    const handleChangePage = async (event, newPage) => {
      setPage(newPage)
      await receiveRateGroupCustoms(newPage, rowsPerPage)
    };

    const handleLimitPage = async (value) => {
      setRowsPerPage(value)
      setPage(1)
      await receiveRateGroupCustoms(1, value)
    }
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event.target.value);
      setPage(0);
    };
    
    const handleOpen = async (modal, data = {}) => {
      if(modal == 'bulkDelete'){ 
        let fill = await filteringRateCustom(data)
        setModalData(fill)
      }
      else{setModalData(data)}
      setModal(modal)
      setOpen(true);
      // console.log(modal,data)
    }

    const handleReloadData = async () => {
      // setRateGroupCustomsDeleted()
      receiveRateGroupCustoms(page, rowsPerPage)
      setOpen(false)
      setBtnDeletedAll(false)
    }
  
    const receiveRateGroupCustoms = async (page, rowsPerPage) => {
      try {
        setRows([])
        let response = await getRateGroupCustomsApi(
          page, rowsPerPage
        )
        setTotalPage(response.data.data.meta.last_page)
        response.data.data.data.forEach(element => {
          setRows(oldItem => [...oldItem, { 
            id: element.id, label: element.label, 
            depcity_id: element.departureGroup.id,
            depcity: element.departureGroup.label, 
            descity_id: element.destinationGroup.id,
            descity: element.destinationGroup.label,
            busid: element.busType.id,
            bustype: element.busType.label, 
            depdateStart: element.departure_start_date, 
            depdateEnd: element.departure_end_date, 
            price: element.price }])
        });
      } catch (error) {
        console.log(error)
      }
    }

  
  
    //react
    useEffect(() => {
      receiveRateGroupCustoms(page, rowsPerPage)
    }, []);

  
    useEffect(() => {
      if (selectedAll) {
        setBtnDeletedAll(true)
      } else {
        setBtnDeletedAll(false)
      }
    }, [selectedAll])
  
    useEffect(() => {
      if (RateGroupCustomsDeleted.length >= 2) {
        setBtnDeletedAll(true)
      } else {
        setBtnDeletedAll(false)
      }
    }, [RateGroupCustomsDeleted]);


    /// Filter
    const [departureDate, setDepartureDate] = React.useState(null);
    const [departureDateDayJs, setDepartureDateDayJs] = useState(null);
    const [departureDateEnd, setDepartureDateEnd] = React.useState(null);
    const [departureDateEndDayJs, setDepartureDateEndDayJs] = useState(null);
    ///
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h3 className={classes.cardTitleWhite}>Daftar Harga Rute Perubahan</h3>
            <p className={classes.cardCategoryWhite}>
              Daftar Harga Rute Perubahan tiap kota asal & tujuan
            </p>
          </CardHeader>
          <CardBody>
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
              {btnDeletedAll ?
                  <Button variant="contained" color="danger" onClick={() => handleOpen('bulkDelete', RateGroupCustomsDeleted)}>
                    Bulk Delete
                  </Button>
                  : null
                }
                <Button variant="contained" color="primary" onClick={() => handleOpen('add')}>
                  Tambah Rute
                </Button>
            </Stack>
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Filter Tanggal Mula"
                    value={departureDateDayJs}
                    onChange={(newValue) => { setDepartureDateDayJs(newValue); setDepartureDate(newValue ? newValue.format('YYYY-MM-DD'): null) }}
                  />
                </DemoContainer>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Filter Tanggal Akhir"
                    value={departureDateEndDayJs}
                    onChange={(newValue) => { setDepartureDateEndDayJs(newValue); setDepartureDateEnd(newValue ? newValue.format('YYYY-MM-DD'): null) }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <Button variant="contained" color="primary" onClick={handleFilter}>
                Cari
              </Button>
              <Button variant="contained" color="info" onClick={handleReloadData}>
                Refresh
              </Button>
            </Stack>
              <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <input type="checkbox" onChange={toggleCheckboxAll} />
                      {/* <input type="checkbox" /> */}
                    </StyledTableCell>
                    <StyledTableCell>ID</StyledTableCell>
                    <StyledTableCell>Nama Rate</StyledTableCell>
                    <StyledTableCell>Grup Kota Asal</StyledTableCell>
                    <StyledTableCell>Grup Kota Tujuan</StyledTableCell>
                    <StyledTableCell>Tipe Bus</StyledTableCell>
                    <StyledTableCell>Harga Custom</StyledTableCell>
                    <StyledTableCell>Tgl Berlaku Mula</StyledTableCell>
                    <StyledTableCell>Tgl Berlaku Akhir</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <input
                          type="checkbox"
                          name="check_list"
                          onChange={toggleCheckbox}
                          value={row.id}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.label}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.depcity}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.descity}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.bustype}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {currencyFormatter(row.price)}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.depdateStart).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {moment(row.depdateEnd).format("DD/MM/YYYY")}
                      </StyledTableCell>
                      {/* <StyledTableCell component="th" scope="row">
                        {
                          row.city.map((element)=>(
                            <span>{element.label}, </span>
                          ))
                        }
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <DataSummaryIcon >
                          <div className="icon mr-1">
                            <EditOutlined onClick={() => handleOpen('update', row)} />
                          </div>
                          <div className="icon mr-1">
                            <DeleteOutlined color="warning" onClick={() => handleOpen('delete', row)} />
                          </div>
                        </DataSummaryIcon>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
              <Limit value={rowsPerPage} onChange={handleLimitPage} />
              <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
            </Box>
          </CardBody>
        </Card>
      </GridItem>
      {open && <AddRateGroupCustomsList open={open} modal={modal} modalData={modalData} setOpen={setOpen} setReloadData={handleReloadData}/>}
    </GridContainer>
    
  );
}
