import {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { pink } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';

// costumize component
import Button from "components/CustomButtons/Button.js";

const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SeatModal = ({open, closeModal, seatData, seatUpdate}) => {
    const [selectedValue, setSelectedValue] = useState('available');
    
    //methods
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleClose = () => {
        closeModal();
    };
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });
    const handleUpdateSeat = () => {
        const seat = {...seatData, seatStatus: selectedValue}
        seatUpdate(seat)
        handleClose()
    }
    
    useEffect(() => {
        setSelectedValue(seatData.seatStatus)
    }, [seatData]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...styleBox, width: 400 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ubah Status Kursi:
                    </Typography>
                    <Grid container spacing={1} alignItems="start">
                        <Grid item xs={4}>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('available')}
                                sx={{
                                    color: 'green',
                                    '&.Mui-checked': {
                                        color: 'green',
                                    },
                                }} />
                                <p mb={0}> Available</p>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('blocked')}
                                sx={{
                                    color: 'red',
                                    '&.Mui-checked': {
                                        color: 'red',
                                    },
                                }}/>
                                <p mb={0}> Blocked</p>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('sold')}
                                sx={{
                                    color: 'orange',
                                    '&.Mui-checked': {
                                        color: 'orange',
                                    },
                                }}/>
                                <p mb={0}> Sold</p>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('agent-available')}
                                sx={{
                                    color: 'lightblue',
                                    '&.Mui-checked': {
                                        color: 'lightblue',
                                    },
                                }}/>
                                <p mb={0}> Agent available</p>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('agent-sold')}
                                sx={{
                                    color: 'blue',
                                    '&.Mui-checked': {
                                        color: 'blue',
                                    },
                                }}/>
                                <p mb={0}> Agent sold</p>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('available')}
                                sx={{
                                    color: 'green',
                                    '&.Mui-checked': {
                                        color: 'green',
                                    },
                                }} />
                                <p mb={0}> Available</p>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Radio {...controlProps('blocked')}
                                sx={{
                                    color: 'red',
                                    '&.Mui-checked': {
                                        color: 'red',
                                    },
                                }}/>
                                <p mb={0}> Blocked</p>
                            </Box>
                        </Grid> */}
                    </Grid>
                    <Box display="flex" justifyContent="end">
                        <Button
                            color="outline"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleUpdateSeat}
                        >
                            Update Kursi
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default SeatModal;