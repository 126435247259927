import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getTransactionApi = async(
  page = 1, limit = 20, filterTransactionDateStart, filterTransactionDateEnd, filterDepartureDate, search, departureLabel
)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(filterTransactionDateStart) {
    url.searchParams.append('filterTransactionDateStart', filterTransactionDateStart);
  }
  if(filterTransactionDateEnd) {
    url.searchParams.append('filterTransactionDateEnd', filterTransactionDateEnd);
  }
  if(filterDepartureDate) {
    url.searchParams.append('filterDepartureDate', filterDepartureDate);
  }
  url.searchParams.append('search', search);
  url.searchParams.append('departureLabel', departureLabel);
  return await httpClientPrivate.get(`/transactions?${url.href.split('?')[1]}`, accessToken());
}

export const getTransactionDetailApi = async (id) => {
  return await httpClientPrivate.get(`/transactions/${id}`, accessToken());
}