import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

//Routes
export const getRouteApi = async(page = 1, rowsPerPage = 20, search = "")=>{
  const url = new URL('https://example.local');
  if(page) {
    url.searchParams.append('page', page);
  }
  if(rowsPerPage) {
    url.searchParams.append('limitPerPage', rowsPerPage);
  }
  url.searchParams.append('search', search);
  return await httpClientPrivate.get(`/routes?${url.href.split('?')[1]}`, accessToken());
}
export const getRouteByIdApi = async(routeId)=>{
  return await httpClientPrivate.get(`/routes/${routeId}`, accessToken());
}
export const saveRouteApi = async(body)=>{
  return await httpClientPrivate.post(`/routes`, body ,accessToken());
}
export const updateRouteApi = async(id, body)=>{
  return await httpClientPrivate.put(`/routes/${id}`, body,accessToken());
}
export const deleteRouteApi = async(id)=>{
  return await httpClientPrivate.delete(`/routes/${id}`, accessToken());
}
export const bulkDeleteRouteApi = async(body)=>{
  return await httpClientPrivate.post(`/routes/delete`, body, accessToken());
}

export const getRateGroupDefaultApi = async(page,rowsPerPage)=>{
  return await httpClientPrivate.get(`/rate-groups-default?page=${page}&limitPerPage=${rowsPerPage}`, accessToken());
}
export const saveRateGroupDefaultApi = async(body)=>{
  return await httpClientPrivate.post(`/rate-groups-default`, body, accessToken());
}
export const updateRateGroupDefaultApi = async(RateGroupId,body)=>{
  return await httpClientPrivate.put(`/rate-groups-default/${RateGroupId}`, body, accessToken());
}
export const deleteRateGroupDefaultApi = async(RateGroupId)=>{
  return await httpClientPrivate.delete(`/rate-groups-default/${RateGroupId}`, accessToken());
}
export const bulkDeleteRateGroupDefaultApi = async(body)=>{
  return await httpClientPrivate.post(`/rate-groups-default/delete`, body, accessToken());
}


// Rate Group customs Price
export const getRateGroupCustomsApi = async(page,rowsPerPage,filterDepartureDateStart, filterDepartureDateEnd)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', rowsPerPage);
  if(filterDepartureDateStart) {
    url.searchParams.append('filterDepartureDateStart', filterDepartureDateStart);
  }
  if(filterDepartureDateEnd) {
    url.searchParams.append('filterDepartureDateEnd', filterDepartureDateEnd);
  }
  return await httpClientPrivate.get(`/rate-group-customs?${url.href.split('?')[1]}`, accessToken());
}
export const saveRateGroupCustomsApi = async(body)=>{
  return await httpClientPrivate.post(`/rate-group-customs`, body, accessToken());
}
export const updateRateGroupCustomsApi = async(RateGroupId,body)=>{
  return await httpClientPrivate.put(`/rate-group-customs/${RateGroupId}`, body, accessToken());
}
export const deleteRateGroupCustomsApi = async(RateGroupId)=>{
  return await httpClientPrivate.delete(`/rate-group-customs/${RateGroupId}`, accessToken());
}
export const bulkDeleteRateGroupCustomsApi = async(body)=>{
  return await httpClientPrivate.post(`/rate-group-customs/delete`, body, accessToken());
}