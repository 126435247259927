import React from "react";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Search from "@material-ui/icons/Search";

const SearchTable = ({name, onChange}) => {
    return(
        <div>
            <TextField
                label={name}
                id="outlined-start-adornment"
                sx={{ width: '35ch' }}
                InputProps={{
                    endAdornment: <InputAdornment position="start"><Search/></InputAdornment>,
                }}
                onChange={(e)=> onChange(e.target.value)}
            />
        </div>
    );
};

export default SearchTable;