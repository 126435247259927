import { getLocalStorage } from "utils/localStorage"

// config
export const accessToken = () => {
  let config = {}
  if(localStorage.getItem('access_token')){
    config = {
      headers: {
          Authorization: `Bearer ${getLocalStorage('access_token').token}`,
          'Content-Type': 'application/json'
      }
    }
    return config
  }
  else {
    return ''
  }
}
// config
export const accessToken2 = () => {
  let config = {}
  if(localStorage.getItem('access_token')){
    config = {
      headers: {
          Authorization: `Bearer ${getLocalStorage('access_token').token}`,
          'Content-Type': 'multipart/form-data'
      }
    }
    return config
  }
  else {
    return ''
  }
}