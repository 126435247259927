import { httpClient } from "./axios";
import { accessToken } from "./token";

export const loginApi = async(body)=>{
  return await httpClient.post(`/login`, body);
}
export const settingGetServiceFeeApi = async()=>{
  return await httpClient.get(`/settings/service-fee`, accessToken());
}

export const settingUpdateServiceFeeApi = async(body)=>{
  return await httpClient.put(`/settings/service-fee`, body, accessToken());
}

export const settingGetServicePointsApi = async()=>{
  return await httpClient.get(`/settings/points`, accessToken());
}

export const settingUpdateServicePointsApi = async(body)=>{
  return await httpClient.put(`/settings/points`, body, accessToken());
}
