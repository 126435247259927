import React, {useState, useEffect, Fragment} from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import {styleBox} from '../../../../utils/views/style'

// apis
import { getTransactionDetailApi } from '../../../../apis/transaction';
import moment from 'moment';
import { Stack } from '@mui/system';
import currencyFormatter from '../../../../utils/currencyFormatter';

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  blackColor: {
    color: 'black'
  }
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [detailData, setDetailData] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  
  // methods
  const handleClose = () => {
    setOpen(false)
  };
  const classes = useStyles();

  const receiveTransactionDetail = async() =>{
    try {
      let response = await getTransactionDetailApi(modalData.id)
      setDetailData(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  
  useEffect(async () => {
    await receiveTransactionDetail()
  }, []);

  if(!detailData) {
    return ''
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} 
      >
        <Box sx={{ ...styleBox, width: '65vw' }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Detail Transaksi
          </Typography>
          <Box fullWidth>
            <form>
            <Stack direction="row" justifyContent="between" alignItems="start" spacing={3} style={{marginBottom: '32px'}}>
              <Stack justifyContent="start" alignItems="center" spacing={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={moment(detailData.transactionDate).format("DD/MM/YYYY")}
                  label="Tanggal Transaksi"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={detailData.buyerName}
                  label="Nama Pembeli"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={detailData.buyerPhone}
                  label="Nomor HP Pembeli"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
              </Stack>
              <Stack justifyContent="start" alignItems="center" spacing={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={detailData.busRoute}
                  label="Rute"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={moment(detailData.departureDate).format("DD/MM/YYYY")}
                  label="Tanggal Keberangkatan"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="between" alignItems="start" spacing={3} style={{marginBottom: '32px'}}>
              <Stack justifyContent="start" alignItems="center" spacing={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={currencyFormatter(detailData.price)}
                  label="Harga Tiket"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={detailData.ticketCount}
                  label="Jumlah Tiket"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={currencyFormatter(detailData.price * detailData.ticketCount)}
                  label="Subtotal"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
              </Stack>
              <Stack justifyContent="start" alignItems="center" spacing={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={currencyFormatter(detailData.serviceFee)}
                  label="Biaya Layanan"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={currencyFormatter(detailData.discountPoints)}
                  label="Diskon Point"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  value={currencyFormatter(detailData.discountPromo)}
                  label="Diskon Promo"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
              </Stack>
              <Stack justifyContent="start" alignItems="center" spacing={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  value={currencyFormatter(detailData.grandTotal)}
                  label="Grand Total"
                  // disabled // can't change color if disabled
                  onChange={() => {}}
                />
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="between" alignItems="start" spacing={3}>
              {
                detailData.passengers.map((p, i) => (
                  <Stack justifyContent="start" alignItems="center" spacing={2}>
                    <Fragment key={i}>
                      <TextField
                        margin="normal"
                        fullWidth
                        value={p.name}
                        label="Nama Penumpang"
                        // disabled // can't change color if disabled
                        onChange={() => {}}
                      />
                      <TextField
                        margin="normal"
                        fullWidth
                        value={p.phone}
                        label="Nomor HP"
                        // disabled // can't change color if disabled
                        onChange={() => {}}
                      />
                      <TextField
                        margin="normal"
                        fullWidth
                        value={p.seatNumber}
                        label="Nomor Kursi"
                        // disabled // can't change color if disabled
                        onChange={() => {}}
                      />
                    </Fragment>
                  </Stack>
                ))
              }
            </Stack>
            <center>
              <p>{errorMessage}</p>
            </center>
            <Box display="flex" justifyContent="center">
              <Button
                color="outline"
                onClick={handleClose}
              >
                Kembali
              </Button>
            </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
