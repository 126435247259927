import axios from 'axios'

const config = {
  headers:{
    "content-type": "application/json",
  }
}
const config2 = {
  headers:{
    "content-type": "multipart/form-data",
  }
}
export const httpClient = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
    config
});
export const httpClientPrivate = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
});
httpClientPrivate.interceptors.response.use(
  function (response) {
      return response;
  }, 
  function (error) {
    if (error.response.data.message === "Unauthorized" || error.response.data.message === "Unauthenticated") {
        localStorage.clear();
        window.location.href = '/signin'
    }
    return Promise.reject(error);
});
export const httpClientPrivateMultipart = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}/api`,
    config2
});
httpClientPrivateMultipart.interceptors.response.use(
  function (response) {
      return response;
  }, 
  function (error) {
    if (error.response.data.message === "Unauthorized" || error.response.data.message === "Unauthenticated") {
        localStorage.clear();
        window.location.href = '/signin'
    }
    return Promise.reject(error);
});