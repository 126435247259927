import React, {useState, useEffect} from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import {styleBox} from '../../../../utils/views/style'

// apis
import { saveCityApi, updateCityApi, deleteCityApi, bulkDeleteCityApi } from "apis/city";
import { bulkDeleteBusApi, deleteBusApi, getBusTypeApi, saveBusApi, updateBusApi } from '../../../../apis/bus';

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [selectBusType, setSelectBusType] = useState(0)
  const [label, setLabel] = useState('')
  const [policeNumber, setPoliceNumber] = useState('')
  const [busTypeRows, setBusTypeRows] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  
  // methods
  const handleClose = () => {
    setOpen(false)
    if (modal == 'bulkDelete') setReloadData()
  };

  const handleSaveBus = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      console.log('masuk');
      let response = await saveBusApi({busTypeId: selectBusType, label, policeNumber})
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateBus = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      console.log(policeNumber);
      await updateBusApi(modalData.id,{busTypeId: selectBusType, label, policeNumber})
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteBus = async () => {
    try {
      setErrorMessage('')
      let response = await deleteBusApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }


  const bulkDelete = async () => {
    try {
      let idsBulk =[]
      modalData.forEach((item)=>{
        idsBulk.push(item.id)
      })
      let response = await bulkDeleteBusApi({ ids: idsBulk })
      setReloadData()
    } catch (error) {
      setBtnDelete(true)
      setErrorMessage(error.response.data.errors[0].message)
      if (error.response.data.data.agents) setErrorForeignMessage(error.response.data.data.agents)
    }
  }
  const classes = useStyles();
  
  useEffect(() => {
    receiveBusType()
    if (modal != 'add') {
      setSelectBusType(modalData.busTypeId)
      setLabel(modalData.label)
      setPoliceNumber(modalData.policeNumber)
    }
  }, []);

  const receiveBusType = async () => {
    try {
      setBusTypeRows([])
      let response = await getBusTypeApi()
      response.data.data.forEach(element => {
        setBusTypeRows(oldItem => [...oldItem, { id: element.id, code: element.code, label: element.label, totalSeat: element.totalSeat }])
      });
    } catch (error) {
      setErrorMessage(error)
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} 
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ? 
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Bus' : 'Update Bus'}
              </Typography>
              <Box fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveBus : handleUpdateBus}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select"
                    helperText="Please select bus type"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    value={selectBusType}
                    onChange={e => setSelectBusType(e.target.value)}
                    >
                    {busTypeRows.map((option, index) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    label="Nama Bus"
                    autoComplete="busLabel"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={policeNumber}
                    onChange={e => setPoliceNumber(e.target.value)}
                    label="Nopol"
                    autoComplete="policeNumber"
                    autoFocus
                  />
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ? 
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Bus dengan ID
                    { 
                      modalData.map((item)=>(
                        <span> {item.id}, </span>
                      ))
                    }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
            :
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Apakah Anda ingin menghapus Bus <span>{label}</span> ?
              </Typography>
              <center>
                <p>{errorMessage}</p>
              </center>
              <Box display="flex" justifyContent="end">
                <Button
                  color="outline"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {
                  !btnDelete ?
                    <Button
                      color="primary"
                      onClick={handleDeleteBus}
                    >
                      Delete
                    </Button>
                    : ''
                }
              </Box>
            </Box>
        }
        
      </Modal>
    </div>
  );
};

export default BasicModal;
