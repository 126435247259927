import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getPromoApi = async()=>{
  return await httpClientPrivate.get(`/promos`, accessToken());
}
export const savePromoApi = async(body)=>{
  return await httpClientPrivate.post(`/promos`, body,accessToken());
}
export const updatePromoApi = async(id, body)=>{
  return await httpClientPrivate.put(`/promos/${id}`, body,accessToken());
}
export const deletePromoApi = async(id)=>{
  return await httpClientPrivate.delete(`/promos/${id}`, accessToken());
}
export const bulkDeletePromoApi = async(body)=>{
  return await httpClientPrivate.post(`/promos/delete`, body, accessToken());
}