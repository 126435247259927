import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from "components/CustomButtons/Button.js";
import moment from 'moment';

import { settingGetServicePointsApi, settingUpdateServicePointsApi } from "../../apis/login";
import { element } from 'prop-types';



export default function PointFeePage() {
    const [percentage, setPercentage] = useState('')
    const [maxAmount, setMaxAmount] = useState('')
    const [dateCreated, setDateCreated] = useState('')
    const [dateChanged, setDateChanged] = useState('')
    const [editState, setEditState] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getPointValue = async() => {
        try {
            let pointsValue = await settingGetServicePointsApi()
            setPercentage(pointsValue.data.data.percentage);
            setMaxAmount(pointsValue.data.data.maxAmount);
            setDateCreated(moment(pointsValue.data.data.created_at).format('YYYY-MM-DD HH:mm:ss'));
            setDateChanged(moment(pointsValue.data.data.updated_at).format('YYYY-MM-DD HH:mm:ss'));
            
        } catch (error){
            console.log(error)
        }
    }

    const handleEditButton = () => {
        setEditState(!editState);
    }

    const handleUpdateFee = async (event) => {
        if(editState){
            try{
                event.preventDefault()
                setErrorMessage('')
                await settingUpdateServicePointsApi({percentage: percentage, maxAmount: maxAmount})
                getPointValue()
                setEditState(!editState);
            } catch (error) {
                if (error.response.data.errors.message) {
                  setErrorMessage(error.response.data.errors.message)
                } else {
                  setErrorMessage(error.response.data.errors[0].message)
                }
            }
        }
    }

    useEffect(() => {
        getPointValue()
    }, []);

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h6" component="h2">
      Pengaturan Pendapatan Poin untuk tiap Pembelian
      </Typography>
      <TextField
       id="outlined-basic"
       label="Persentase (%)" 
       variant="outlined"
       value={percentage}
       disabled={!editState}
       onChange={e => setPercentage(e.target.value)}
     />

      <TextField
       id="filled-basic" 
       label="Jumlah Terbanyak (Rp)" 
       variant="outlined" 
       value={maxAmount}
       disabled={!editState}
       onChange={e => setMaxAmount(e.target.value)}
     />

      <TextField
       id="standard-basic" 
       label="Tanggal dibuat" 
       variant="outlined"
       value={dateCreated}
       disabled 
     />
      <TextField
       id="standard-basic" 
       label="Tanggal diubah" 
       variant="outlined"
       value={dateChanged}
       disabled 
     />

      <Button
        color="primary"
        onClick={editState? handleUpdateFee : handleEditButton}
        >
        {editState ? 'Simpan' : 'Ubah'}
     </Button>
    </Box>
  );
}