import React, {useState, useEffect} from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import {styleBox} from '../../../../utils/views/style'

// apis
import { saveCityApi, updateCityApi, deleteCityApi, bulkDeleteCityApi } from "apis/city";

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ 
  open, 
  modal, 
  modalData, 
  setOpen, 
  setReloadData,
  daftarKota
 }) => {
  // variable
  const [cities, setCities] = useState([])
  const [cityName, setCityName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  const [transitTime, setTransitTime] = useState(dayjs());

  const arrayDaftarKota = Object.values(daftarKota)

  // methods
  const handleClose = () => {
    setOpen(false)
    if (modal == 'bulkDelete') setReloadData()
  };

  const handleSaveCity = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      let response = await saveCityApi({
        label: cityName,
        transitTime: transitTime
      })
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateCity = async(event) => {
    try{
      event.preventDefault()
      setErrorMessage('')
      let response = await updateCityApi(modalData.id,{
        label: cityName, 
        transitTime: transitTime
      })
      setReloadData()
    }catch(error){
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteCity = async () => {
    try {
      setErrorMessage('')
      let response = await deleteCityApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }


  const bulkDelete = async () => {
    try {
      let idsBulk =[]
      modalData.forEach((item)=>{
        idsBulk.push(item.id)
      })
      let response = await bulkDeleteCityApi({ ids: idsBulk })
      setReloadData()
    } catch (error) {
      setBtnDelete(true)
      setErrorMessage(error.response.data.errors[0].message)
      if (error.response.data.data.agents) setErrorForeignMessage(error.response.data.data.agents)
    }
  }
  const classes = useStyles();

  const handleFormTimeChange = (index, event) => {
    data[index]['time'] = dayjs(event)
  }
  
  useEffect(() => {
    if (modal != 'add') {
      setCityName(modalData.kota)
      setTransitTime(modalData.transitTime)
    }
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }} 
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ? 
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Kota' : 'Update Kota'}
              </Typography>
              <Box>
                <form onSubmit={modal == 'add' ? handleSaveCity : handleUpdateCity}>


                <TextField
                    value={cityName}
                    onChange={e => setCityName(e.target.value)}
                    label="Nama Kota"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ? 
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Kota dengan ID Kota
                    { 
                      modalData.map((item)=>(
                        <span> {item.kota}, </span>
                      ))
                    }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                  {
                    errorForeignMessage.length > 0 ?
                      <p>List Agent:</p>
                    : null
                  }
                  {
                    errorForeignMessage.map((item)=>(
                      <span>{item.label}, </span>
                    ))
                  }
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
            :
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Apakah Anda ingin menghapus Kota <span>{cityName}</span> ?
              </Typography>
              <center>
                <p>{errorMessage}</p>
              </center>
              <Box display="flex" justifyContent="end">
                <Button
                  color="outline"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                {
                  !btnDelete ?
                    <Button
                      color="primary"
                      onClick={handleDeleteCity}
                    >
                      Delete
                    </Button>
                    : ''
                }
              </Box>
            </Box>
        }
        
      </Modal>
    </div>
  );
};

export default BasicModal;