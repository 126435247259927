import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getCrewApi = async(page = 1, limit = 20, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/crews?${url.href.split('?')[1]}`, accessToken());
}
export const saveCrewApi = async(body)=>{
  return await httpClientPrivate.post(`/crews`, body,accessToken());
}
export const updateCrewApi = async(id, body)=>{
  return await httpClientPrivate.put(`/crews/${id}`, body,accessToken());
}
export const deleteCrewApi = async(id)=>{
  return await httpClientPrivate.delete(`/crews/${id}`, accessToken());
}
export const bulkDeleteCrewApi = async(body)=>{
  return await httpClientPrivate.post(`/crews/delete`, body, accessToken());
}