import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from "components/CustomButtons/Button.js";
import moment from 'moment';

import { settingGetServiceFeeApi, settingUpdateServiceFeeApi } from "../../apis/login";
import { element } from 'prop-types';



export default function ServiceFeePage() {
    const [amount, setAmount] = useState('')
    const [code, setCode] = useState('')
    const [label, setLabel] = useState('')
    const [dateCreated, setDateCreated] = useState('')
    const [dateChanged, setDateChanged] = useState('')
    const [editState, setEditState] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const getServiceFeeValue = async() => {
        try {
            let pointsValue = await settingGetServiceFeeApi()
            setAmount(pointsValue.data.data.amount);
            setCode(pointsValue.data.data.code);
            setLabel(pointsValue.data.data.label);
            setDateCreated(moment(pointsValue.data.data.created_at).format('YYYY-MM-DD HH:mm:ss'));
            setDateChanged(moment(pointsValue.data.data.updated_at).format('YYYY-MM-DD HH:mm:ss'));
            
        } catch (error){
            console.log(error)
        }
    }

    const handleEditButton = () => {
        setEditState(!editState);
    }

    const handleUpdateFee = async (event) => {
        if(editState){
            try{
                event.preventDefault()
                setErrorMessage('')
                await settingUpdateServiceFeeApi({amount: amount, label: label})
                getServiceFeeValue()
                setEditState(!editState);
            } catch (error) {
                if (error.response.data.errors.message) {
                  setErrorMessage(error.response.data.errors.message)
                } else {
                  setErrorMessage(error.response.data.errors[0].message)
                }
            }
        }
    }

    useEffect(() => {
        getServiceFeeValue()
    }, []);

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h6" component="h2">
        Pengaturan Jumlah Biaya Layanan untuk tiap Transaksi
      </Typography>
      <TextField
       id="outlined-basic"
       label="kode fee" 
       variant="outlined"
       value={code}
       disabled
     />

      <TextField
       id="filled-basic" 
       label="label" 
       variant="outlined" 
       value={label}
       disabled
     />

    <TextField
       id="outlined-basic"
       label="Biaya (Rp)" 
       variant="outlined"
       value={amount}
       disabled={!editState}
       onChange={e => setAmount(e.target.value)}
     />

      <TextField
       id="standard-basic" 
       label="Tanggal dibuat" 
       variant="outlined"
       value={dateCreated}
       disabled 
     />
      <TextField
       id="standard-basic" 
       label="Tanggal diubah" 
       variant="outlined"
       value={dateChanged}
       disabled 
     />

      <Button
        color="primary"
        onClick={editState? handleUpdateFee : handleEditButton}
        >
        {editState ? 'Simpan' : 'Ubah'}
     </Button>
    </Box>
  );
}