import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

//component
import SeatModal from './SeatModal';

const ButtonCustom = styled('div')(props => ({
    backgroundColor: 
        props.bgColor == 'blocked' ? 'red' 
        : props.bgColor == 'available' ? 'green' 
        : props.bgColor == 'sold' ? 'orange' 
        : props.bgColor == 'agent-available' ? 'lightblue' 
        : props.bgColor == 'agent-sold' ? 'blue'
        : 'dark',
    color: 'white',
    textAlign: 'center',
    fontWeight: '600',
    boxShadow: '1px 1px 0px dark',
    borderRadius: '8px',
    padding: '8px 8px'
}))
const InstructionCustom = styled('div')(props => ({
    backgroundColor: 
        props.bgColor == 'blocked' ? 'red' 
        : props.bgColor == 'available' ? 'green' 
        : props.bgColor == 'sold' ? 'orange' 
        : props.bgColor == 'agent-available' ? 'lightblue' 
        : props.bgColor == 'agent-sold' ? 'blue'
        : 'dark',
    color: 'white',
    textAlign: 'center',
    fontWeight: '300',
    boxShadow: '1px 1px 0px dark',
    borderRadius: '8px',
    padding: '8px 8px',
    marginRight: '8px',
    marginTop: '16px'
}))
const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: 'black',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: 'white',
}));
const fontHeader = {
    marginBottom: '8px !important',
    marginTop: '0px !important'
}
const styleBox = {
    border: '1px solid black',
    marginLeft: '4px',
}


const DepartureTypeBusTemplate = ({ seats, updateSeat }) =>{
    const [seatModal, setSeatModal] = useState(false)
    const [seatUpdateBackup, setSeatUpdateBackup] = useState([])
    const [choosenSeat, setChoosenSeat] = useState({})
    const [seat, setSeat] = useState([
        {
            number: 1,
            seatStatus: "blocked"
        },
        {
            number: 2,
            seatStatus: "blocked"
        },
        {
            number: 3,
            seatStatus: "blocked"
        },
        {
            number: 4,
            seatStatus: "blocked"
        },
        {
            number: 5,
            seatStatus: "blocked"
        },
        {
            number: 6,
            seatStatus: "blocked"
        },
        {
            number: 7,
            seatStatus: "blocked"
        },
        {
            number: 8,
            seatStatus: "blocked"
        },
        {
            number: 9,
            seatStatus: "blocked"
        },
        {
            number: 10,
            seatStatus: "blocked"
        },
        {
            number: 11,
            seatStatus: "blocked"
        },
        {
            number: 12,
            seatStatus: "blocked"
        },
        {
            number: 13,
            seatStatus: "blocked"
        },
        {
            number: 14,
            seatStatus: "blocked"
        },
        {
            number: 15,
            seatStatus: "blocked"
        },
        {
            number: 16,
            seatStatus: "blocked"
        },
        {
            number: 17,
            seatStatus: "blocked"
        },
        {
            number: 18,
            seatStatus: "blocked"
        },
        {
            number: 19,
            seatStatus: "blocked"
        },
        {
            number: 20,
            seatStatus: "blocked"
        },
        {
            number: 21,
            seatStatus: "blocked"
        },
        {
            number: 22,
            seatStatus: "blocked"
        },
        {
            number: 23,
            seatStatus: "blocked"
        },
    ])

    // methods
    const openSeatModal = (data) => {
        setSeatModal(true)
        setChoosenSeat(data)
    }
    const closeSeatModal = () => {
        setSeatModal(false)
    }
    const handleUpdateSeat = (data) => {
        let s = seat
        s.forEach((element) => {
            if (element.number == data.number) {
                element.seatStatus = data.seatStatus
            }
        })
        setTimeout(() => {
            setSeat(s)
        }, 2000);
    }
    useEffect(() => {
        if (seats.length > 0) {
            setSeat(seats)
        }
    }, []);
    useEffect(() => {
        updateSeat(seat)
    }, [seat]);
    return(
        <Box>
            <p sx={fontHeader}>Peta Bus</p>
            <Grid container spacing={2} sx={styleBox}>
                <Grid item xs={6}>
                    <Grid item xs={6}>
                        <Item2>Kernet</Item2>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Item2>Supir</Item2>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[0])} bgColor={seat[0].seatStatus}>1</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[1])} bgColor={seat[1].seatStatus}>2</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[2])} bgColor={seat[2].seatStatus}>3</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[3])} bgColor={seat[3].seatStatus}>4</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[4])} bgColor={seat[4].seatStatus}>5</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[5])} bgColor={seat[5].seatStatus}>6</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[6])} bgColor={seat[6].seatStatus}>7</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[7])} bgColor={seat[7].seatStatus}>8</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[8])} bgColor={seat[8].seatStatus}>9</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Item2>Toilet</Item2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[10])} bgColor={seat[10].seatStatus}>11</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[11])} bgColor={seat[11].seatStatus}>12</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[9])} bgColor={seat[9].seatStatus}>10</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[13])} bgColor={seat[13].seatStatus}>14</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[14])} bgColor={seat[14].seatStatus}>15</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[12])} bgColor={seat[12].seatStatus}>13</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[16])} bgColor={seat[16].seatStatus}>17</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[17])} bgColor={seat[17].seatStatus}>18</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[15])} bgColor={seat[15].seatStatus}>16</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[19])} bgColor={seat[19].seatStatus}>20</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[20])} bgColor={seat[20].seatStatus}>21</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[18])} bgColor={seat[18].seatStatus}>19</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[21])} bgColor={seat[21].seatStatus}>22</ButtonCustom>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonCustom onClick={() => openSeatModal(seat[22])} bgColor={seat[22].seatStatus}>23</ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={2} display='flex' justifyContent='start'>
                Keterangan Kursi:
            </Box>
            <Box display='flex' style={{flexWrap:'wrap'}} justifyContent='start' alignItems="center">
                <InstructionCustom bgColor={'blocked'}>
                    Blocked
                </InstructionCustom>
                <InstructionCustom bgColor={'available'}>
                    Available
                </InstructionCustom>
                <InstructionCustom bgColor={'sold'}>
                    Sold
                </InstructionCustom>
                <InstructionCustom bgColor={'agent-sold'}>
                    Agent sold
                </InstructionCustom>
                <InstructionCustom bgColor={'agent-available'}>
                    Agent available
                </InstructionCustom>
            </Box>
            <SeatModal open={seatModal} seatData={choosenSeat} closeModal={closeSeatModal} seatUpdate={handleUpdateSeat} />
        </Box>
    )
}

export default DepartureTypeBusTemplate;