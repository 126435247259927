/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect, PrivateRoute } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { getLocalStorage } from "utils/localStorage";

// core components
import Admin from "./layouts/Admin.js";
import RTL from "./layouts/RTL";
import SignIn from "./views/SignIn/SignInSide.js";

import "./assets/css/material-dashboard-react.css";

function RequireAuth({children, ...rest}) {
  let token = getLocalStorage('access_token')
  return (
    <Route
      {...rest}
      render={() => {
        return token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
            }}
          />
        );
      }}
    />
  );
  }

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/signin" component={SignIn} />
        <RequireAuth>
          <Admin/>
        </RequireAuth>
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
