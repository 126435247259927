import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";
import { getLocalStorage } from "utils/localStorage"

export const getRoleApi = async(page = 1, limit = 20, adminOnly = false, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  url.searchParams.append('adminOnly', adminOnly);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/roles?${url.href.split('?')[1]}`, accessToken());
}
export const getRoleApiById = async(RoleId)=>{
  return await httpClientPrivate.get(`/roles/${RoleId}`, accessToken());
}
export const saveRoleApi = async(body)=>{
  return await httpClientPrivate.post(`/roles`, body,accessToken());
}
export const updateRoleApi = async(id, body)=>{
  return await httpClientPrivate.put(`/roles/${id}`, body,accessToken());
}
export const deleteRoleApi = async(id)=>{
  return await httpClientPrivate.delete(`/roles/${id}`, accessToken());
}

// config
export const roleId = () => {
  let roleId
  if(localStorage.getItem('roleId')){
    roleId = localStorage.getItem('roleId')
    return roleId
  }
  else {
    return ''
  }
}