import React, { useState, useEffect } from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@mui/material/styles';
import { Stack } from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import { styleBox } from '../../../../utils/views/style'

// apis
import { saveRouteApi, updateRouteApi, deleteRouteApi, bulkDeleteRouteApi } from "apis/routerate";
import { getCityApi } from "../../../../apis/city";

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
    overflowY: 'auto',
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    overflowY: 'auto',
  },
};

const useStyles = makeStyles(stylesField);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowY: 'auto',
    },
  },
};

function getStyles(name, routes, theme) {
  return {
    fontWeight:
      routes.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [routes, setRoutes] = useState([])
  const [rows, setRows] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  const [routeName, setRouteName] = useState('')
  const [routeCode, setRouteCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [formData, setFormData] = useState([
    {label: '', busDay: '', busTime: '', city_id_admin: ''}
  ]);

  const theme = useTheme();
  // methods

  const addFormStopsField = () => {
    let label
    let busDay
    let busTime
    setFormData([...formData, { label, busDay, busTime}]);
  };

  const handleRemoveField = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };

  const handleFormFieldChange = (index, value) => {
    const newFormData = [...formData];
    newFormData[index]['label'] = value;
    setFormData(newFormData);
  };
  const handleFormTimeChange = (index, event) => {
    let data = [...formData];
    console.log('event', dayjs(event))
    data[index]['busTime'] = dayjs(event)
    setFormData(data);
  }
  const handleFormDayChange = (index, event) => {
    let data = [...formData];
    data[index]['busDay'] = event
    setFormData(data);
  }
  const handleClose = () => {
    setOpen(false)
    if (modal == 'bulkDelete') setReloadData()
  };
  
  const handleSaveRoute = async (event) => {
    try {
      event.preventDefault()
      let stops = await filteringRoute()
      // Create the JSON object
      const jsonObject = {
        routeName,
        routeCode,
        routeStops: stops.map((value, index) => ({
          cityId: value.label,
          order: index + 1,
          busTime: value.busTime,
          busDay: value.busDay,
        })),
      };
      setErrorMessage('')
      let response = await saveRouteApi({ 
        label: jsonObject.routeName, 
        code: jsonObject.routeCode, 
        routeStops: jsonObject.routeStops })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message){
        setErrorMessage(error.response.data.errors.message)
      }else{
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateRoute = async (event) => {
    try {
      event.preventDefault()
      let stops = await filteringRoute()
      // Create the JSON object
      const jsonObject = {
        routeName,
        routeCode,
        routeStops: stops.map((value, index) => ({
          cityIdAdmin: value.city_id_admin,
          order: index + 1,
          busTime: value.busTime,
          busDay: value.busDay,
        })),
      };
      // console.log(jsonObject)
      setErrorMessage('')
      await updateRouteApi(modalData.route_id_admin,{ 
        label: jsonObject.routeName, 
        code: jsonObject.routeCode, 
        routeStops: jsonObject.routeStops })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteRoute = async () => {
    try {
      setErrorMessage('')
      let response = await deleteRouteApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.data.errors[0].message)
      setBtnDelete(true)
    }
  }

  const bulkDelete = async () => {
    try {
      let response = await bulkDeleteRouteApi({ ids: modalData })
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }
  
  const receiveCity = async () => {
    try {
      setErrorMessage('')
      let response = await getCityApi()
      response.data.data.forEach((element) => {
        setRows((oldItem) => [...oldItem, {  ...element, value: element.id }])
      })
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const filteringRoute = async () =>{
    let filter = formData
    // rows.forEach((item)=>{
    //   formData.forEach((items) => {
    //     if(items == item.label){
    //       return filter.push(item.value)
    //     }
    //   })
    // })
    rows.forEach((item) => {
      formData.forEach((items, index) => {
        if(item.label == items.label){
          filter[index]['label'] = item.value
          filter[index]['city_id_admin'] = item.city_id_admin
        }
      })
    })
    return filter
  }

  const classes = useStyles();

  useEffect(() => {
    receiveCity()
    if (modal != 'add' && modal != 'bulkDelete' ) {
      handleRemoveField()
      setRouteName(modalData.label)
      setRouteCode(modalData.code)
      modalData.rute.forEach((newItem, index)=>{
        setRoutes((oldItem)=>[...oldItem, newItem.city.label])
        setFormData((oldItem)=>[...oldItem, {label: newItem.city.label, busDay: newItem.busDay, busTime: dayjs(newItem.busTime)}])
        // setBusTime((oldItem)=>[...oldItem, newItem.busTime])
        // setBusDay((oldItem)=>[...oldItem, newItem.busDay])
      })
    }
  }, []);


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ?
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Rute' : 'Update Rute'}
              </Typography>
              <Box overflow="auto" maxHeight="80vh" fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveRoute : handleUpdateRoute}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={routeName}
                    onChange={e => setRouteName(e.target.value)}
                    id="email"
                    label="Nama Rute"
                    autoComplete="nama rute"
                    autoFocus
                    />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={routeCode}
                    onChange={e => setRouteCode(e.target.value)}
                    id="email"
                    label="Kode Rute"
                    autoComplete="kode rute"
                    autoFocus
                    />
                  <FormControl sx={{ width: '100%' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Kota Tujuan
                    </Typography>
                      {formData.map((value, index) => (
                        <div key={index}>
                          <Select
                            id={index}
                            key={index}
                            type="text"
                            required
                            fullWidth
                            value={value.label}
                            onChange={(e) => handleFormFieldChange(index, e.target.value)}
                          >
                          {rows.map((element) => (
                            <MenuItem
                              key={element.id}
                              value={element.label}
                              style={getStyles(element.label, routes, theme)}
                            >
                              {element.label}
                            </MenuItem>
                          ))}
                          </Select>
                          <Stack direction="row" spacing={2}>
                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                              <InputLabel id="demo-simple-select-label">Hari</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={value.busDay}
                                label="Hari"
                                key={index}
                                onChange={event => handleFormDayChange(index, event.target.value)}
                              >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                              </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DateTimeField', 'DateTimeField']}>
                                  <TimePicker
                                      ampm={false}
                                      label="Jam Transit"
                                      value={value.busTime}
                                      key={index}
                                      onChange={event => handleFormTimeChange(index, event)}
                                      />
                              </DemoContainer>
                            </LocalizationProvider>
                          </Stack>
                          <Button onClick={() => handleRemoveField(index)}>Hapus Tujuan</Button>
                        </div>
                        ))}
                    <Stack>
                      <Button color="outline" onClick={addFormStopsField}>Tambah Tujuan</Button>
                    </Stack>
                  </FormControl>
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ?
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Rute dengan ID Rute
                  {
                    modalData.map((item) => (
                      <span> {item}, </span>
                    ))
                  }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                  {
                    errorForeignMessage.length > 0 ?
                      <p>List:</p>
                      : null
                  }
                  {
                    errorForeignMessage.map((item) => (
                      <span>{item.label}, </span>
                    ))
                  }
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ? 
                    <Button
                      color="primary"
                      onClick={bulkDelete}
                    >
                      Delete
                    </Button>
                    : ''
                  }
                </Box>
              </Box>
              :
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Rute <span>{routeName}</span> ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={handleDeleteRoute}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
        }

      </Modal>
    </div>
  );
};

export default BasicModal;

