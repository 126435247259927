import React, {useEffect, useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';

// core components
import Box from "@mui/material/Box";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Stack } from "@mui/system";
import TableContainer from "@mui/material/TableContainer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Limit from "components/Limit/Limit.js";
import SearchTable from "components/SearchTable/SearchTable.js";

//apis
import { getMemberApi } from "apis/member.js";

// lib
import { useDebouncedCallback } from "use-debounce";

///////////////
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#E3E6E5',
    color: '#474A57',
    fontWeight: 700,
    fontSize: '16px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const customPagination = () => ({
  marginTop: '16px',
  width: '100%',
  '& .Mui-selected': {
    backgroundColor: '#FF0202 !important',
    color: 'white',
  },
  justifyContent: 'space-between'
})
const DataSummaryIcon = styled('div')(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#247BA0',
}))
//////////////

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "kota", headerName: "Kota", width: 130 },
];


const useStyles = makeStyles(styles);

export default function MeberList() {
  const classes = useStyles();
  const [rows, setRows] =  useState([])
  //////
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalPage, setTotalPage] = React.useState(1);
  //////
  const [search, setSearch] = useState("");
  
  const handleChangePage = async (event, newPage) => {
    setPage(newPage)
    await receiveMember(newPage, rowsPerPage, search)
  };

  const handleLimitPage = async (value) => {
    setRowsPerPage(value)
    setPage(1)
    await receiveMember(1, value, search)
  }

  const handleSearch = useDebouncedCallback((value) => {
    setSearch(value)
    setPage(1)
    receiveMember(1, rowsPerPage, value)
   }, 1000)

  const receiveMember = async(
    page, rowsPerPage, search
  ) =>{
    try {
      setRows([])
      let response = await getMemberApi(
        page, rowsPerPage, search
      )
      setTotalPage(response.data.data.meta.last_page)
      setRows(response.data.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  //react
  useEffect(() => {
    receiveMember(page, rowsPerPage, search)
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Daftar Member</h4>
            <p className={classes.cardCategoryWhite}>
              Daftar Member
            </p>
          </CardHeader>
          <CardBody>
            <Stack direction="row" justifyContent="end" marginBottom={2}>
              <SearchTable name="Cari Nama atau Email" onChange={handleSearch}/>
            </Stack>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ID Pembeli</StyledTableCell>
                    <StyledTableCell>Nama</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>HP</StyledTableCell>
                    <StyledTableCell>Alamat</StyledTableCell>
                    <StyledTableCell>Point</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.address}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.userPoint?.amount ? row.userPoint?.amount : "-"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box display="flex" justifyContent="between" alignItems="center" sx={customPagination}>
              <Limit value={rowsPerPage} onChange={handleLimitPage} />
              <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
            </Box>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////


