import React, {useState, useEffect} from 'react';
import Box from "@mui/material/Box";

// costumize component
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardBody from "components/Card/CardBody";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import removeIcon from '../../assets/img/remove-icon.png'

import { updateMediaApi, getMediaApi } from '../../apis/cms'

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};
const useStyles = makeStyles(styles);
export default function CmsForm(){
    const classes = useStyles();
    const [file, setFile] = useState(null)
    const [errorMsg, setErrorMsg] = useState('')
    const [imagePreview, setImagePreview] = useState(null)
    const [selectMedia, setSelectMedia] = useState({
        id:0,
        name: 'about1'
    })
    const [image, setImage] = useState({})
    const [optionsMedia, setOptionsFile] = useState([
        {
            id: 0,
            name: 'about1',
        },
        {
            id: 1,
            name: 'about2',
        },
        {
            id: 2,
            name: 'about3',
        },
        {
            id: 3,
            name: 'about_thumb_a1',
        },
        {
            id: 4,
            name: 'about_thumb_a2',
        },
        {
            id: 5,
            name: 'about_thumb_a3',
        },
        {
            id: 6,
            name: 'about_thumb_a4',
        },
        {
            id: 7,
            name: 'about_thumb_b1',
        },
        {
            id: 8,
            name: 'about_thumb_b2',
        },
        {
            id: 9,
            name: 'about_thumb_b3',
        },
        {
            id: 10,
            name: 'about_thumb_b4',
        },
        {
            id: 11,
            name: 'about_thumb_b5',
        },
        {
            id: 12,
            name: 'about_thumb_b6',
        },
        {
            id: 13,
            name: 'about_thumb_b7',
        },
        {
            id: 14,
            name: 'about_thumb_b8',
        },

    ])
    const handleFileChange = async(newFile) => {
        await setFile(newFile.target.files)
        setImagePreview( URL.createObjectURL(newFile.target.files[0]) )
    }
    const handleReset = (e) =>{
        setFile(e.target.value = null)
        setImagePreview(null)
        setErrorMsg('')
    }
    const updateMedia = async(e) =>{
        try{
            e.preventDefault()
            setErrorMsg('')
            let fd = new FormData()
            fd.append(`code[0]`, selectMedia.name)
            fd.append(`file[0]`, file[0])
            let response = await updateMediaApi(fd)
        }catch(e){
            setErrorMsg(e.response.data.errors[0].message)
            console.log(e)
        }
    }
    const getMedia = async() =>{
        try{
            let body = {
                codes: [selectMedia.name]
            }
            let response = await getMediaApi(body)
            setImage(response.data.data[0])
        }catch(e){
            console.log(e)
        }
    }
    const itemData = [
        // {
        //     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        //     title: 'Breakfast',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        //     title: 'Burger',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        //     title: 'Camera',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        //     title: 'Coffee',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        //     title: 'Hats',
        // },
        {
            img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
            title: 'Honey',
        },
        // {
        //     img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        //     title: 'Basketball',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        //     title: 'Fern',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        //     title: 'Mushrooms',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        //     title: 'Tomato basil',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        //     title: 'Sea star',
        // },
        // {
        //     img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        //     title: 'Bike',
        // },
    ];
    useEffect(() => {
        getMedia()
        setFile(null)
        setErrorMsg('')
    }, [selectMedia]);
    return(
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>CMS</h4>
                            <p className={classes.cardCategoryWhite}>
                                Uploading atau update image pada landing ticket app
                            </p>
                        </CardHeader>
                        <CardBody>
                            <Box position="relative">
                                <GridContainer>
                                    <GridItem xs={6} md={6} lg={6}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label="Media"
                                            margin="dense"
                                            fullWidth
                                            value={selectMedia}
                                            onChange={e => setSelectMedia(e.target.value)}
                                        >
                                            {optionsMedia.map((option, index) => (
                                                <MenuItem key={option.id} value={option}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </GridItem>
                                </GridContainer>
                            </Box>
                            <Box position="relative">
                                <GridContainer>
                                    <GridItem xs={6} sm={6} md={6}>
                                        { image.code && !file ?
                                                <img
                                                    src={`${image.url}`}
                                                    srcSet={`${image.url}`}
                                                    alt={image.code}
                                                    loading="lazy"
                                                    key={image}
                                                    style={{maxWidth: '400px'}}
                                                />
                                            :
                                            <Box>
                                                <img src={imagePreview} style={{ maxWidth: '400px' }} />
                                            </Box>
                                        }
                                        {/* <img position=" absolute" src={removeIcon} style={{width:'20px', height:'20px',position:'absolute', right:'4px', top: '4px'}}/> */}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={6} sm={6} md={6}>
                                        <input type="file" id="avatar" name="avatar" accept="image/*" onChange={(e) => handleFileChange(e)} onClick={e => (e.target.value = null)} style={{marginTop:'16px'}} />
                                    </GridItem>
                                </GridContainer>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <p style={{color: 'red', fontWeight: 600}}>{errorMsg}</p>
                            </Box>
                            <Box display="flex" justifyContent="end">
                                <Button type="button" color="outline" onClick={(e)=>handleReset(e)}>
                                    Reset
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={(e)=>updateMedia(e)}
                                >
                                    Update Media
                                </Button>
                            </Box>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    )
}