import React, { useState, useEffect } from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { makeStyles } from "@material-ui/core/styles";

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import { styleBox } from '../../../../utils/views/style'

// apis
import { saveCityGroupApi, updateCityGroupApi, deleteCityGroupApi, bulkDeleteCityGroupApi, getCityApi } from "apis/city";

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);
import { useTheme } from '@mui/material/styles';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, cities, theme) {
  return {
    fontWeight:
      cities.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [cities, setCities] = useState([])
  const [cityRows, setCityRows] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  const [cityGroupName, setCityGroupName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])

  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCities(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // methods
  const handleClose = () => {
    setOpen(false)
    if (modal == 'bulkDelete') setReloadData()
  };
  
  const handleSaveCityGroup = async (event) => {
    try {
      event.preventDefault()
      let cityIds = await filteringCity()
      setErrorMessage('')
      let response = await saveCityGroupApi({ label: cityGroupName, cityMemberIds: cityIds })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message){
        setErrorMessage(error.response.data.errors.message)
      }else{
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateCityGroup = async (event) => {
    try {
      event.preventDefault()
      let cityIds = await filteringCity()
      setErrorMessage('')
      let response = await updateCityGroupApi(modalData.id, { label: cityGroupName, cityMemberIds: cityIds })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteCityGroup = async () => {
    try {
      setErrorMessage('')
      let response = await deleteCityGroupApi(modalData.id)
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.data.errors[0].message)
      setBtnDelete(true)
    }
  }

  const bulkDelete = async () => {
    try {
      let response = await bulkDeleteCityGroupApi({ ids: modalData })
      setReloadData()
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
      setBtnDelete(true)
    }
  }
  
  const receiveCity = async () => {
    try {
      setErrorMessage('')
      let response = await getCityApi()
      response.data.data.forEach((element) => {
        setCityRows((oldItem) => [...oldItem, { value: element.id, label: element.label }])
      })
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
    }
  }

  const filteringCity = async () =>{
    let filter = []
    cityRows.forEach((item)=>{
      cities.forEach((items) => {
        if(items == item.label) return filter.push(item.value)
      })
    })
    return filter
  }

  const classes = useStyles();

  useEffect(() => {
    receiveCity()
    if (modal != 'add' && modal != 'bulkDelete' ) {
      setCityGroupName(modalData.label)
      modalData.city.forEach((newItem)=>{
        setCities((oldItem)=>[...oldItem, newItem.label])
      })
    }
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ?
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Grup Kota' : 'Update Grup Kota'}
              </Typography>
              <Box fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveCityGroup : handleUpdateCityGroup}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={cityGroupName}
                    onChange={e => setCityGroupName(e.target.value)}
                    id="email"
                    label="Nama Grup Kota"
                    autoComplete="nama grup kota"
                    autoFocus
                    />
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-chip-label">Kota</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      required
                      fullWidth
                      multiple
                      value={cities}
                      onChange={handleChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Kota" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {cityRows.map((element) => (
                        <MenuItem
                          key={element.id}
                          value={element.label}
                          style={getStyles(element.label, cities, theme)}
                        >
                          {element.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ?
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Group Kota dengan ID Group Kota
                  {
                    modalData.map((item) => (
                      <span> {item}, </span>
                    ))
                  }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                  {
                    errorForeignMessage.length > 0 ?
                      <p>List:</p>
                      : null
                  }
                  {
                    errorForeignMessage.map((item) => (
                      <span>{item.label}, </span>
                    ))
                  }
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ? 
                    <Button
                      color="primary"
                      onClick={bulkDelete}
                    >
                      Delete
                    </Button>
                    : ''
                  }
                </Box>
              </Box>
              :
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Kota <span>{cityGroupName}</span> ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={handleDeleteCityGroup}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
        }

      </Modal>
    </div>
  );
};

export default BasicModal;

