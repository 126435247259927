import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import { styleBox } from '../../../../utils/views/style'

// apis
import { bulkDeleteRateGroupCustomsApi, deleteRateGroupCustomsApi, saveRateGroupCustomsApi, updateRateGroupCustomsApi } from '../../../../apis/routerate';
import { getCityGroupApi } from '../../../../apis/city';
import { getBusTypeApi } from '../../../../apis/bus';

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [label, setLabel] = useState('')
  const [amount, setAmount] = useState(0)
  const [selectCityGroupDep, setSelectCityGroupDep] = useState('')
  const [selectCityGroupAriv, setSelectCityGroupAriv] = useState('')
  const [selectBus, setSelectBus] = useState('')
  const [cityRows, setCityRows] = useState([])
  const [busRows, setBusRows] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  const [departureDate, setDepartureDate] = useState(dayjs());
  const [departureDateEnd, setDepartureDateEnd] = useState(dayjs());
  const [departureDateDayJs, setDepartureDateDayJs] = useState(dayjs());
  const [departureDateEndDayJs, setDepartureDateEndDayJs] = useState(dayjs());

  // methods
  const handleClose = () => {
    setOpen(false)
    if (modal == 'bulkDelete') setReloadData()
  };

  const handleSaveRateGroupCustoms = async (event) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      let response = await saveRateGroupCustomsApi(
        {
           label, departureStartDate: departureDate, 
           departureEndDate: departureDateEnd, 
           originRateGroupId: selectCityGroupDep,
           destinationRateGroupId: selectCityGroupAriv, 
           busTypeId: selectBus,
           price: amount 
        }
      )
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateRateGroupCustoms = async (event) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      let response = await updateRateGroupCustomsApi(modalData.id,{
        label, originRateGroupId: selectCityGroupDep, 
        destinationRateGroupId: selectCityGroupAriv, 
        busTypeId: selectBus, price: amount })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteRateGroupCustoms = async () => {
    try {
      setErrorMessage('')
      let response = await deleteRateGroupCustomsApi(modalData.id)
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
      setBtnDelete(true)
    }
  }


  const bulkDelete = async () => {
    try {
      let idsBulk = []
      modalData.forEach((item) => {
        idsBulk.push(item.id)
      })
      let response = await bulkDeleteRateGroupCustomsApi({ ids: idsBulk })
      setReloadData()
    } catch (error) {
      setBtnDelete(true)
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
      if (error.response.data.data.agents) setErrorForeignMessage(error.response.data.data.agents)
    }
  }

  const receiveCityGroup = async () => {
    try {
      setErrorMessage('')
      let response = await getCityGroupApi()
      response.data.data.forEach((element)=>{
        setCityRows((oldItem)=>[ ...oldItem, {value: element.id, label: element.label}])
      })
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
    }
  }

  const receiveBus = async () => {
    try {
      setErrorMessage('')
      let response = await getBusTypeApi()
      response.data.data.forEach((element)=>{
        setBusRows((oldItem)=>[ ...oldItem, {value: element.id, label: element.label}])
      })
    } catch (error) {
      setErrorMessage(error.response.data.errors[0].message)
    }
  }

  const classes = useStyles();

  useEffect(() => {
    receiveCityGroup()
    receiveBus()
    if (modal != 'add' && modal != 'bulkDelete' ){
      setLabel(modalData.label)
      setDepartureDateDayJs(dayjs(modalData.depdateStart))
      setDepartureDateEndDayJs(dayjs(modalData.depdateEnd))
      setAmount(modalData.price)
      setSelectCityGroupDep(modalData.depcity_id)
      setSelectCityGroupAriv(modalData.descity_id)
      setSelectBus(modalData.busid)
    }
    setDepartureDate(dayjs(modalData.startTime).format('YYYY-MM-DD'))
    setDepartureDateEnd(dayjs(modalData.startTime).format('YYYY-MM-DD'))

  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ?
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Harga Customs' : 'Update Harga Customs'}
              </Typography>
              <Box overflow="auto" maxHeight="80vh" fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveRateGroupCustoms : handleUpdateRateGroupCustoms}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={label}
                    onChange={e => setLabel(e.target.value)}
                    label="Nama Rate"
                    autoFocus
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimeField']}>
                      <DatePicker
                        disabled={modal == 'add' ? false : true}
                        label="Tanggal Mula Berlaku"
                        value={departureDateDayJs}
                        onChange={(newValue) => { setDepartureDateDayJs(newValue); setDepartureDate(newValue.format('YYYY-MM-DD')) }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimeField']}>
                      <DatePicker
                        disabled={modal == 'add' ? false : true}
                        label="Tanggal Akhir Berlaku"
                        value={departureDateEndDayJs}
                        onChange={(newValue) => { setDepartureDateEndDayJs(newValue); setDepartureDateEnd(newValue.format('YYYY-MM-DD')) }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <TextField
                    id="outlined-select-currency"
                    disabled={modal == 'add' ? false : true}
                    select
                    label="Kelompok Kota Keberangkatan"
                    helperText="Pilih kelompok kota keberangkatan"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    value={selectCityGroupDep}
                    onChange={e => setSelectCityGroupDep(e.target.value)}
                  >
                    {cityRows.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    disabled={modal == 'add' ? false : true}
                    select
                    label="Kelompok Kota Kedatangan"
                    helperText="Pilih kelompok kota Kedatangan"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    value={selectCityGroupAriv}
                    onChange={e => setSelectCityGroupAriv(e.target.value)}
                  >
                    {cityRows.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    disabled={modal == 'add' ? false : true}
                    select
                    label="Tipe Bus"
                    helperText="Pilih Tipe Bus"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    value={selectBus}
                    onChange={e => setSelectBus(e.target.value)}
                  >
                    {busRows.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="normal"
                    type="number"
                    required
                    fullWidth
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    helperText={`Harga dalam rupiah`}
                    label="Harga"
                    autoFocus
                  />
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ?
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Harga Customs
                  {
                    modalData.map((item) => (
                      <span> {item.label}, </span>
                    ))
                  }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
              :
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Harga Customs <span>{label}</span> ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={handleDeleteRateGroupCustoms}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
        }

      </Modal>
    </div>
  );
};

export default BasicModal;
