import React, {useEffect, useState} from "react";
import Notifications from "@material-ui/icons/Notifications";
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import { getNotification } from "apis/notification";
import Clear from "@material-ui/icons/Clear";
import Refresh from "@material-ui/icons/Refresh";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    
};
const styleScrollBox = {
    padding:"5px 40px",
    minHeight: "150px",
    maxHeight: "450px",
    overflowY: 'scroll',
}

export default function Notification({value, onChange}){
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [dataNotif, setDataNotif] = useState([])
    const [page, setPage] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(1);

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
        await getDataNotification(newPage)
    };

    const getDataNotification = async(newPage) => {
        try{
            const response = await getNotification(newPage, 20)
            setDataNotif(response?.data?.data?.data)
            setTotalPage(response?.data?.data?.meta?.last_page)
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        getDataNotification(page)
    },[])

    return(
        <div>
            <Notifications style={{cursor:"pointer", width:"20px", marginTop:"9px"}} onClick={handleOpen}/>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" padding={3} paddingRight={5} paddingLeft={5} borderBottom="1px solid #D0D0D0">
                        <Stack direction="row" alignItems="center" gap={3}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Notification
                            </Typography>
                            <div 
                                style={{
                                    display:"flex", 
                                    justifyContent:"center", 
                                    alignItems:"center", 
                                    gap:4, 
                                    cursor:"pointer"
                                }}
                                onClick={()=> getDataNotification(page)}
                            >
                                Refresh
                                <Refresh/>
                            </div>
                        </Stack>
                        <Clear
                            onClick={handleClose}
                            style={{
                                cursor:"pointer"
                            }}
                        />
                    </Stack>
                    <Box style={styleScrollBox}>
                        {dataNotif.length ? dataNotif?.map((item, i)=>
                            <div
                                key={i}
                                style={{
                                    borderBottom:"1px solid #B3B0B0",
                                    padding:"10px 0"
                                }}
                            >
                                <Typography mb fontWeight="fontWeightMedium" fontSize={20}>
                                    {item.title}
                                </Typography>
                                <Typography mb>
                                    {item.body}
                                </Typography>
                            </div>
                        ) :
                            <Typography marginTop="30px" color="grey">"Anda Belum memiliki notifikasi.."</Typography>
                        }
                    </Box>
                    {dataNotif.length ?
                        <Stack direction="row" justifyContent="end" padding={3} paddingRight={5}>
                            <Pagination count={totalPage} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                        </Stack> : null
                    }
                </Box>
            </Modal>
        </div>
    );
};