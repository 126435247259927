import { httpClientPrivate } from "./axios";
import { accessToken } from "./token";

export const getCityApi = async(page = 1, limit = 20, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/cities?${url.href.split('?')[1]}`, accessToken());
}
export const getCityApiById = async(CityId)=>{
  return await httpClientPrivate.get(`/cities/${CityId}`, accessToken());
}
export const saveCityApi = async(body)=>{
  return await httpClientPrivate.post(`/cities`, body,accessToken());
}
export const updateCityApi = async(id, body)=>{
  return await httpClientPrivate.put(`/cities/${id}`, body,accessToken());
}
export const deleteCityApi = async(id)=>{
  return await httpClientPrivate.delete(`/cities/${id}`, accessToken());
}
export const bulkDeleteCityApi = async(body)=>{
  return await httpClientPrivate.post(`/cities/delete`, body, accessToken());
}

// City Group
export const getCityGroupApi = async(page = 1, limit = 20, isPagination)=>{
  const url = new URL('https://example.local');
  url.searchParams.append('page', page);
  url.searchParams.append('limitPerPage', limit);
  if(isPagination)url.searchParams.append('isPagination', isPagination)
  return await httpClientPrivate.get(`/rate-groups?${url.href.split('?')[1]}&loadCities=true`, accessToken());
}
export const saveCityGroupApi = async(body)=>{
  return await httpClientPrivate.post(`/rate-groups`, body, accessToken());
}
export const updateCityGroupApi = async(cityGroupId,body)=>{
  return await httpClientPrivate.put(`/rate-groups/${cityGroupId}`, body, accessToken());
}
export const deleteCityGroupApi = async(cityGroupId)=>{
  return await httpClientPrivate.delete(`/rate-groups/${cityGroupId}`, accessToken());
}
export const bulkDeleteCityGroupApi = async(body)=>{
  return await httpClientPrivate.post(`/rate-groups/delete`, body, accessToken());
}