import React, { useState, useEffect } from "react";

// @material-ui/core components
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';

// costumize component
import Button from "components/CustomButtons/Button.js";

// utils 
import { styleBox } from '../../../utils/views/style'

// apis
import { saveCrewApi, updateCrewApi, deleteCrewApi, bulkDeleteCrewApi } from "apis/crew";

const stylesField = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(stylesField);

// eslint-disable-next-line react/prop-types
const BasicModal = ({ open, modal, modalData, setOpen, setReloadData }) => {
  // variable
  const [cities, setCities] = useState([])
  const [crewName, setCrewName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorForeignMessage, setErrorForeignMessage] = useState([])
  const [btnDelete, setBtnDelete] = useState(false)
  const [phone, setPhone] = useState(0)
  const [selectJobTitle, setSelectJobTitle] = useState('')
  const [jobTitleOptions, setJobTitleOptions] = useState([
    {
      id: 1,
      jobTitle: "sopir",
    },
    {
      id: 2,
      jobTitle: "kernet",
    },
    {
      id: 3,
      jobTitle: "pramugara/i",
    },
  ])

  // methods
  const handleClose = () => {
    setOpen(false)
    if (modal == 'bulkDelete') setReloadData()
  };

  const handleSaveCrew = async (event) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      let response = await saveCrewApi({ name: crewName, phone: phone, jobTitle: selectJobTitle })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleUpdateCity = async (event) => {
    try {
      event.preventDefault()
      setErrorMessage('')
      let response = await updateCrewApi(modalData.id, { name: crewName, phone: phone, jobTitle: selectJobTitle })
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
    }
  }

  const handleDeleteCity = async () => {
    try {
      setErrorMessage('')
      let response = await deleteCrewApi(modalData.id)
      setReloadData()
    } catch (error) {
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
      setBtnDelete(true)
    }
  }


  const bulkDelete = async () => {
    try {
      let idsBulk = []
      modalData.forEach((item) => {
        idsBulk.push(item.id)
      })
      let response = await bulkDeleteCrewApi({ ids: idsBulk })
      setReloadData()
    } catch (error) {
      setBtnDelete(true)
      if (error.response.data.errors.message) {
        setErrorMessage(error.response.data.errors.message)
      } else {
        setErrorMessage(error.response.data.errors[0].message)
      }
      if (error.response.data.data.agents) setErrorForeignMessage(error.response.data.data.agents)
    }
  }
  const classes = useStyles();

  useEffect(() => {
    if (modal != 'add' && modal != 'bulkDelete' ){
      setCrewName(modalData.name)
      setSelectJobTitle(modalData.jobTitle)
      setPhone(modalData.phone)
    }
    console.log(modalData)
  }, []);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {
          modal != 'delete' && modal != 'bulkDelete' ?
            <Box sx={{ ...styleBox, width: 400 }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {modal == 'add' ? 'Tambah Crew' : 'Update Crew'}
              </Typography>
              <Box fullWidth>
                <form onSubmit={modal == 'add' ? handleSaveCrew : handleUpdateCity}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    value={crewName}
                    onChange={e => setCrewName(e.target.value)}
                    id="email"
                    label="Nama Crew"
                    autoComplete="nama crew"
                    autoFocus
                  />
                  <TextField
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    id="phone-agent"
                    type="number"
                    label="Phone Number"
                    autoComplete="phone number"
                    margin="normal"
                    fullWidth
                    required
                  />
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Select"
                    helperText="Please select crew"
                    margin="normal"
                    required
                    fullWidth
                    autoFocus
                    value={selectJobTitle}
                    onChange={e => setSelectJobTitle(e.target.value)}
                  >
                    {jobTitleOptions.map((option) => (
                      <MenuItem key={option.id} value={option.jobTitle}>
                        {option.jobTitle}
                      </MenuItem>
                    ))}
                  </TextField>
                  <center>
                    <p>{errorMessage}</p>
                  </center>
                  <Box display="flex" justifyContent="end">
                    <Button
                      color="outline"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      {modal == 'add' ? 'Tambah' : 'Update'}
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            : modal == 'bulkDelete' ?
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Crew dengan ID Crew
                  {
                    modalData.map((item) => (
                      <span> {item.name}, </span>
                    ))
                  }
                  ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                  {
                    errorForeignMessage.length > 0 ?
                      <p>List Agent:</p>
                      : null
                  }
                  {
                    errorForeignMessage.map((item) => (
                      <span>{item.label}, </span>
                    ))
                  }
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={bulkDelete}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
              :
              <Box sx={{ ...styleBox, width: 400 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Apakah Anda ingin menghapus Crew <span>{crewName}</span> ?
                </Typography>
                <center>
                  <p>{errorMessage}</p>
                </center>
                <Box display="flex" justifyContent="end">
                  <Button
                    color="outline"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  {
                    !btnDelete ?
                      <Button
                        color="primary"
                        onClick={handleDeleteCity}
                      >
                        Delete
                      </Button>
                      : ''
                  }
                </Box>
              </Box>
        }

      </Modal>
    </div>
  );
};

export default BasicModal;
